import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const swal = {
    success, error, warning
}

function success(title, text) {
    MySwal.fire({
        icon: 'success',
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000
    })
}

function error(title, text) {
    MySwal.fire({
        icon: 'error',
        title: title,
        text: text,
        showConfirmButton: false
    })
}

function warning(title, text) {
    MySwal.fire({
        icon: 'warning',
        title: title,
        text: text,
        showConfirmButton: true,
        confirmButtonColor: '#D3D731'
    })
}

export default swal;