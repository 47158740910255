import React from "react";
import Placeholder from "../../../../images/product-placeholder.png"
import translation from "../../../../i18n"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import renderPrice from "../../../../utils/renderPrice";
import i18n from "../../../../i18n";

export default class OrderItem extends React.Component {
    roundWeight = (item) => {
        let { weight, amount } = item
        // es 533 => 540
        weight = weight * 1000
        weight = weight / 10;
        weight = Math.ceil(weight);
        weight = weight * 10;
        weight = weight * amount;
        if (weight >= 1000) {
            return `${weight / 1000} kg`
        } else {
            return `${weight} g `
        }
    }

    renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                const label = item.promotions[0].valueType === "UNKNOWN" ? "promo.unknown" : "promo";
                return (
                    <p className="cart-product-description__promo">
                        <FontAwesomeIcon className="mr-1" icon="thumbs-up" size="1x" />
                        {translation.t(label)}
                    </p>
                );
            } else {
                return null;
            }
        }
    }

    renderPrices = (item) => {
        let result;
        if (item.price !== item.netPrice) {
            result =
                <>
                    <nobr><span className="text-strike">
                        {`${renderPrice(item.price.toFixed(2))} \u20AC`}
                    </span></nobr>
                    <nobr><span className="ml-1">
                        {`${renderPrice(item.netPrice.toFixed(2))} \u20AC `}
                    </span></nobr>
                    {item.vatConfigurationId && item.attrib3 &&
                        <p>
                            <span className="product-detail-netPrice d-none d-sm-inline">{`${item.attrib3} \u20AC / kg`}</span>
                        </p>
                    }
                    {item.cartItemRelatedProduct &&
                        <p>
                            <span className="">{`${renderPrice(renderPrice(item.cartItemRelatedProduct?.price.toFixed(2)))}`}</span>
                        </p>
                    }
                </>
        } else {
            result = <div>
                <span>{`${renderPrice(item.netPrice.toFixed(2))} \u20AC `}</span>
                {item.vatConfigurationId && item.attrib3 &&
                    <p>
                        <span className="product-detail-netPrice d-none d-sm-inline">{`${item.attrib3} \u20AC / kg`}</span>
                    </p>
                }
                {item.cartItemRelatedProduct &&
                    <>
                        <br />
                        <span className="product-detail-netPrice">{` ${renderPrice(renderPrice(item.cartItemRelatedProduct?.price.toFixed(2)))} \u20AC`}</span>
                    </>
                }
            </div>
        }
        return result
    }

    renderAmount = (item) => {
        let result;
        if (item.purchaseUm === "PZ") {
            if (item.operatorAmount && item.operatorAmount !== item.amount) {
                result =
                    <>
                        <span className="text-strike">{item.amount}</span>
                        <span className="ml-1">{item.operatorAmount} {i18n.t(item.purchaseUm.toLowerCase())}</span>
                    </>
            } else {
                result = <span>{item.amount} {i18n.t(item.purchaseUm.toLowerCase())}</span>
            }

        } else {
            result =
                <div>
                    <span>{`${item.weight?.toFixed(2)} kg`}</span>
                    {item.cartItemRelatedProduct &&
                        <>
                            <br />
                            <span>{item.cartItemRelatedProduct.amount}</span>
                        </>
                    }
                </div>
        }
        return result;
    }

    renderTotal = (item) => {
        let result;
        if (item.operatorTotal && item.operatorTotal !== item.rowNetPrice) {
            result =
                <nobr>
                    <span className="d-none d-sm-block text-strike">{`${renderPrice(item.rowNetPrice.toFixed(2))} \u20AC`}</span>
                    <span className="ml-1">{`${renderPrice(item.operatorTotal.toFixed(2))} \u20AC `}</span>
                </nobr>
        } else {
            result =
                <>
                    <span>{`${renderPrice(item.rowNetPrice.toFixed(2))} \u20AC`}</span>
                    {item.cartItemRelatedProduct &&
                        <>
                            <br />
                            <span>{` ${renderPrice(item.cartItemRelatedProduct?.rowPrice.toFixed(2))} \u20AC`}</span>
                        </>}
                </>
        }
        return result
    }

    render() {
        const { item } = this.props;
        return (
            <div className="article">
                <div className={`row mt-2 pb-2 pb-sm-0 alert-${item.status}`}>
                    {/* Only for desktop device */}
                    <div className="article__image d-none d-sm-block">
                        <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                            onError={(e) => { e.target.src = Placeholder }}
                        />
                    </div>

                    <div className="col pt-2 px-sm-0 pr-sm-4">
                        <div className="container-fluid article__centerer">
                            <div className="row">

                                {/* Only for mobile device */}
                                <div className="col-3 d-block d-sm-none article__image">
                                    <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                                        onError={(e) => { e.target.src = Placeholder }}
                                    />
                                </div>

                                <div className="col-6 col-sm-4 pr-0 mb-sm-auto">
                                    <div className="article__info">
                                        <p className="article__name">{item.description}</p>
                                        <span className="article__promo">{this.renderPromo(item)}</span>
                                    </div>
                                    {item.cartItemRelatedProduct ?
                                        <p className="article__name">+  {item.cartItemRelatedProduct?.description}</p>
                                        : ""
                                    }
                                </div>

                                <div className="col-3 col-sm-2 text-right px-0 pl-sm-0">
                                    <div className="article__price">
                                        {this.renderPrices(item)}
                                    </div>
                                </div>

                                <div className="col-3 col-sm-1 px-0 pr-sm-auto">
                                    <nobr><p className="d-block d-sm-none" style={{ fontSize: "11px", paddingTop: "3px" }}>{item.status === "danger" && "Non disponibile"}</p></nobr>
                                </div>

                                <div style={item.status === "danger" ? { textDecoration: "line-through" } : null}
                                    className="col-5 col-sm-2 px-0 pr-sm-auto text-center article__amount">
                                    {this.renderAmount(item)}
                                </div>

                                <div style={item.status === "danger" ? { textDecoration: "line-through" } : null}
                                    className="col-4 col-sm-2 px-0 text-right">
                                    <div className="article__total cart-product-total">
                                        {this.renderTotal(item)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
