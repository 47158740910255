import React, { useEffect } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'

const Share = () => {
    console.log(window.location);
    useEffect(() => {
        if(isAndroid){
            window.location.replace("https://play.google.com/store/apps/details?id=com.pggroup.pavipama");
        }
        if(isIOS){
            window.location.replace("https://apps.apple.com/us/app/pavipama/id1574158526");
        }
        if(!isAndroid && !isIOS){
            window.location.replace(location.origin + "/app");
        }
    }, [])

    return (
        <div>
        </div>
    )
}

export default Share