import React from "react"
import AddressSelect from "./AddressSelect/AddressSelect"
import { Translation } from "react-i18next"
import { Redirect } from 'react-router'
import { ecommerceService } from "../../services/ecommerce/EcommerceService"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"
import { storesService } from "../../services/basis/StoresService"

class CartCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryModes: [],
            selectedDeliveryMode: "",
            stores: [],
            selectedStore: "",
            addressId: null,
            addressCity: null,
            firstSlot: null,
            firstSlotLoaded: false,
            slotAvaible: null,
            responseCode: null,
            isOpening: false
        }
    }

    componentDidMount() {
        ecommerceService.deliveryModes()
            .then(data => {
                this.setState({
                    deliveryModes: data
                })
            });
        storesService.storesByDelivery("IN_STORE")
            .then(data => {
                this.setState({ stores: data, });
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.selectedStore !== this.state.selectedStore && this.state.selectedDeliveryMode === "IN_STORE") ||
            prevState.addressId !== this.state.addressId) {
            this.getFirstSlot();
        }
    }

    getFirstSlot = () => {
        const { selectedStore, selectedDeliveryMode, addressCity } = this.state;
        this.setState({ firstSlot: null, firstSlotLoaded: false })
        if (selectedDeliveryMode === "IN_STORE") {
            storesService.firstSlot(selectedStore, selectedDeliveryMode)
                .then(response => {
                    if (response.responseCode && response.responseCode === 2) {
                        this.setState({ slotAvaible: false })
                    } else {
                        this.setState({ firstSlot: response.data, firstSlotLoaded: true, slotAvaible: true })
                    }
                }).catch(error => {
                    console.log(error);
                })
        } else {
            if (selectedDeliveryMode && addressCity) {
                storesService.firstSlot(null, selectedDeliveryMode, addressCity)
                    .then(response => {
                        if (response?.responseCode === 0) {
                            this.setState({ firstSlot: response.data, firstSlotLoaded: true, slotAvaible: true, selectedStore: response.data.storeId })
                        } else {
                            this.setState({ slotAvaible: false, responseCode: response.responseCode })
                        }
                    }).catch(error => {
                        console.log(error);
                    })
            }
        }
    }

    selectDeliveryMode = (deliveryMode, storeId) => {
        this.setState({
            selectedDeliveryMode: deliveryMode,
            selectedStore: storeId,
            addressId: "",
            addressCity: "",
            firstSlot: null,
            firstSlotLoaded: false
        });
    }

    selectAddress = (item) => {
        this.setState({ addressId: item.id, addressCity: item.city });
    }

    isGozo = (city) => {
        return city?.includes("GOZO")
    }

    handleOpen = () => {
        const { selectedDeliveryMode, selectedStore, addressId } = this.state;
        const { open} = this.props;
        this.setState({isOpening: true})
        open(selectedDeliveryMode, selectedStore, addressId, this.afterOpenCart)
    }

    afterOpenCart = () => {
        this.setState({isOpening: false})
    }

    render() {
        const { deliveryModes, selectedDeliveryMode, selectedStore, addressId, responseCode, stores, firstSlot, firstSlotLoaded, slotAvaible, addressCity, isOpening } = this.state;
        const { cart } = this.props;
        if (cart) return <Redirect to="/" />
        else return (
            <Translation>
                {t =>
                    <div className="bg">
                        <div className="container">
                            <div className="col-xs-12 col-md-6 col-lg-5 offset-lg-7 offset-md-6">
                                <div className="wrapper">
                                    <div className="wrapper-box">
                                        <h1 className="cart-create-title">{t("create.cart.title")}</h1>
                                        <div>
                                            <div className="cart-create-select">
                                                {deliveryModes && deliveryModes.map(item => {
                                                    if (item.id === "IN_STORE") {
                                                        return (
                                                            stores && stores.map(store => (
                                                                <div className="radio-button" key={store.id}>
                                                                    <div className="custom-control custom-radio" style={{ minHeight: "unset" }}>
                                                                        <input type="radio" className="custom-control-input"
                                                                            id={store.id}
                                                                            name={store.id}
                                                                            value={true}
                                                                            checked={selectedDeliveryMode === "IN_STORE" && selectedStore === store.id}
                                                                            onChange={() => this.selectDeliveryMode("IN_STORE", store.id)}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor={store.id}>
                                                                            {t(`cart.deliveryMode.${store.id}`)}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        );
                                                    } else return (
                                                        <div className="radio-button" key={item.id}>
                                                            <div className="custom-control custom-radio" style={{ minHeight: "unset" }}>
                                                                <input type="radio" className="custom-control-input"
                                                                    id="isEnabledReplacement"
                                                                    name="isEnabledReplacement"
                                                                    value={true}
                                                                    onChange={() => this.selectDeliveryMode(item.id, "")}
                                                                    checked={selectedDeliveryMode === item.id}
                                                                />
                                                                <label className="custom-control-label" htmlFor="isEnabledReplacement">
                                                                    {t(`cart.deliveryMode.${item.id}`)}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {(selectedDeliveryMode === "AT_HOME" ||
                                                    selectedDeliveryMode === "SHIPPED") &&
                                                    <AddressSelect
                                                        select={this.selectAddress}
                                                        label={t("cartCreate.deliveryAddress")}
                                                    />
                                                }
                                                {firstSlotLoaded && firstSlot && //!this.isGozo(addressCity) &&
                                                    <>
                                                        <div className="first-slot">
                                                            {selectedDeliveryMode === "AT_HOME" ? t("landingPage.firstSlot-delivery") : t("landingPage.firstSlot-pickup")}
                                                            <b> {firstSlot.description}</b> {t("landingPage.atTime")}
                                                            <b> {firstSlot.validFrom}-{firstSlot.validTo} </b>
                                                        </div>
                                                        <div className="first-slot-message">
                                                            * Delivery time slot is for indicative purposes only as this might be booked by someone else by the time you finish your shopping.
                                                        </div>
                                                    </>
                                                }
                                                {/* {this.isGozo(addressCity) &&
                                                    <div className="no-stores">
                                                        Delivery is not available in GOZO
                                                    </div>
                                                } */}
                                                {slotAvaible === false && selectedDeliveryMode === "AT_HOME" && addressId && responseCode === 6008 && //!this.isGozo(addressCity) &&
                                                    <div className="no-stores">
                                                        {t("noAvailabity")}
                                                    </div>
                                                }
                                                {slotAvaible === false && selectedDeliveryMode === "AT_HOME" && addressId && responseCode === 6007 && //!this.isGozo(addressCity) &&
                                                    <div className="no-stores">
                                                        {t("error.noStoreAssociated") + addressCity}
                                                    </div>
                                                }
                                                {slotAvaible === false && selectedDeliveryMode === "IN_STORE" && !firstSlotLoaded && //!this.isGozo(addressCity) &&
                                                    <div className="no-stores">
                                                        {t("noAvailabity")}
                                                    </div>
                                                }
                                                {firstSlotLoaded && !firstSlot && selectedStore && selectedDeliveryMode === "IN_STORE" &&
                                                    <div className="no-stores">
                                                        {t("landingPage.noSlot")} {selectedDeliveryMode === "AT_HOME" ? t("landingPage.delivery") : t("landingPage.pickup")}
                                                    </div>
                                                }
                                                {firstSlotLoaded && !firstSlot && selectedStore && selectedDeliveryMode === "AT_HOME" && addressId &&
                                                    <div className="no-stores">
                                                        {t("landingPage.noSlot")} {selectedDeliveryMode === "AT_HOME" ? t("landingPage.delivery") : t("landingPage.pickup")}
                                                    </div>
                                                }
                                                <button className="cta" style={{ marginBottom: "10px" }}
                                                    disabled={selectedDeliveryMode === "" || !slotAvaible || isOpening
                                                        // ((selectedDeliveryMode === "AT_HOME" || selectedDeliveryMode === "SHIPPED")) ||
                                                        // ((selectedDeliveryMode === "IN_STORE" || selectedDeliveryMode === "DRIVE")) //||
                                                        // // (selectedDeliveryMode !== "" && !slotAvaible)
                                                    }
                                                    onClick={this.handleOpen}>
                                                    <span>{t("create.cart")}</span>
                                                </button>
                                                <div>
                                                    <small>
                                                        Attention:<br />
                                                        <ul className="pl-4">
                                                            <li>
                                                                Kindly note that once selected, this option cannot be changed at a later stage unless the whole cart is deleted. <br />
                                                                Please choose carefully! <br />
                                                            </li>
                                                            <li>
                                                                Kindly note that non completed carts, shall be automatically transferred to an automated shopping list by end of business day!
                                                            </li>
                                                        </ul>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        open: (deliveryMode, storeId, addressId, afterOpenCart) => dispatch(actionCreators.open(deliveryMode, storeId, addressId, afterOpenCart)),
    };
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
        storeId: state.user.storeId
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartCreate));