import React from "react";
import Popup from "reactjs-popup";
import { Translation } from "react-i18next";

export default (props) => (
    <Translation>
        {t =>
            <Popup lockScroll={true} className="popup-size" trigger={props.trigger} modal>
                {close => (
                    <div className="popup">
                        <div className="close" onClick={close}>
                            &times;
                        </div>
                        <div className="popup-header">{props.title}</div>
                        <div className="content text-center">
                            <input style={{ width: "90%" }} className="custom-input mt-3 mb-4" autoFocus name={props.name} value={props.value} onChange={props.onChange} />
                        </div>
                        <div className="actions">
                            <button className="cta abort outline" onClick={() => { close(); }}>
                                <span>{t("cancel")}</span>
                            </button>
                            <button className="cta outline"
                                disabled={props?.value.length <= 0}
                                onClick={() => {
                                    props.action();
                                    close();
                                }}>
                                <span>{props.button}</span>
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        }
    </Translation>
);