import React from 'react'

const TermsAndCondition = () => {
    return (
        <div style={{ padding: "10px" }}>
            <h2>Terms & Conditions </h2><br />
            Welcome to the Terms and Conditions of our website, https://www.pavipama.com.mt/app/ (the
            “Website”). This Website is owned and managed by Pavi Supermarkets Limited (C-29878) and Pama
            Supermarket Limited (C-67445), both having their registered address situated at PG Group Head
            Offices, Pama Shopping Village, Valletta Road, Mosta MST9017, Malta (“We” / “Us” / “Our”), forming
            part of the wider PG Group.
            We operate both Pavi Supermarket, located in Qormi (“Pavi”), and Pama Supermarket, located in
            Mosta (“Pama”), and, through this Website, provide our patrons with the option to purchase products
            online.<br /><br />
            <b>Use of this Site</b><br /><br />
            The provisions of these Terms and Conditions govern the entry, access, connection to or use of our
            Website by its users. For the avoidance of doubt, any reference in these Terms and Conditions to ‘use’
            shall refer to any sort of use of the Website, inclusive of simple entry, access or connection to the same
            Website. These Terms and Conditions form a legally binding agreement between us and the user, and
            you should therefore take the time to read them carefully. By entering, accessing, connecting to or
            using this Website in any manner, you are deemed to have read, understood and agreed to these Terms
            and Conditions. If you do not agree with them, please refrain from using our Website.<br /><br />
            <b>Age Restriction</b><br /><br />
            Use of this Website by persons under the age of 18 is strictly prohibited. By using this Website, you are
            signalling that you are 18 years old or over.<br /><br />
            <b>Online Purchase Process</b><br /><br />
            In order to place your order via this Website, you must first select the service you would like to avail
            yourself of: (i) delivery; (ii) Pavi pick-up; or (iii) Pama pick-up. Once the service is selected, it cannot be
            changed at a later stage, unless the whole cart is deleted by you through our Website.<br />
            <b>Delivery</b><br />
            The ‘Delivery’ option is available to customers situated in Malta and who order products through the
            Website.
            Upon choosing the ‘Delivery’ option, you will be prompted to select a delivery address. This will
            generate an indicative date and time demonstrating the first slot available for delivery.
            After you add the products to your cart, you may place the order by pressing the cart icon, followed
            by the ‘Checkout’ button.
            You will here be redirected to choose your preferred date and approximate time for the delivery
            (subject to availability), following which you will again be redirected to the ‘Personal Details’ section
            setting out your order, the offer you would like to choose (as applicable), and your chosen payment
            method. You may either pay us upon delivery via cash or credit card, or otherwise effect the payment
            online, in which case you will be asked to enter your credit card details.
            Please note that deliveries are only made to the ground floor level entrance of a building (whether at
            your house, your block of apartments, or your business premises).
            Please bear in mind that delivery dates and times are subject to availability, and may therefore change
            depending on our reasonable exigencies.
            In the event that, at the time of delivery, you do not, for any reason, collect your order, we will attempt
            to re-deliver at the next available date and time. In this instance, we shall charge you ten Euro (€10)
            for any such re-delivery.
            In efforts to maintain efficient delivery services, you may only purchase up to ten (10) packets of water
            (each packet comprising a six (6)-pack), of whichever brand, per order.
            In the event that any item is found missing from your order / not in line with expectations upon
            delivery, kindly contact us by calling +356 22700336 on the same day of delivery to be able to assist
            you.<br />
            <b>Delivery Charges</b><br />
            All orders under seventy-five Euro (€75) shall be charged a delivery fee of five Euro (€5). Orders over
            seventy five (€75) are delivered free of charge.
            Pick-ups
            Alternatively, you may choose to order products online through our Website, but then pick them up
            yourself at either Pavi or Pama.
            Upon choosing the Pavi or Pama ‘Pick-up’ option, the Website will provide you with an indicative date
            and approximate time demonstrating the first slot available for pick-up.
            After you add the products to your cart, you may place the order by pressing the cart icon, followed
            by the ‘Checkout’ button.
            You will here be redirected to choose your preferred date and approximate time for pick-up, following
            which you will again be redirected to the ‘Personal Details’ section setting out your order, the offer you
            would like to choose (as applicable), and your chosen payment method. You may either pay us when
            collecting via cash or credit card, or otherwise effect the payment online, in which case you will be
            asked to enter your credit card details.<br />
            <b>Goods</b><br />
            Goods / products are subject to availability and prevailing market conditions. If any goods you wish to
            purchase through our Website are not available at such time your order is being processed by us, we
            will contact you on the telephone/mobile number provided by you, and we shall offer you alternative
            options similar to the goods you would have requested, which may be of a different price.
            Should the alternative product chosen by you increase the total value of your original order, the
            additional cost will be deducted as an ‘extra charge’ from your bank account, and, in utilising this
            service, you are automatically providing us with your authorisation for such deduction. In the event
            that the alternative option chosen by you decreases the total value of your order, we shall refund the
            difference of the order value in due course.<br />
            <b>Offers</b><br />
            Without prejudice to the foregoing, for every one hundred Euro (€100) spent on a single online order,
            you are entitled to one (1) free packet of water (six (6)-pack) and two (2) coupons for one (1) packet
            of water each upon delivery. The coupons may be redeemed by you at either Pavi or Pama, and will
            expire on the date indicated thereon. For the avoidance of doubt, and following expiration, they will
            become void and may therefore no longer be redeemed.
            Please bear in mind that these offers are discretionary and may change from time to time.<br />
            Loyalty Card<br /><br />
            Make the most of our offers – whether shopping in-store at our supermarkets, or online through our
            Website – and apply for our loyalty card. You may apply for the loyalty card for free by visiting our
            Customer Care at each of Pavi and Pama.
            Please contact us by calling +356 22700336 and, or sending an email to info@pavi.com.mt for more
            information.<br /><br />
            Security<br /><br />
            You must not disclose or share your credentials and password since you are solely responsible for all
            activities and orders made under the same. Should you suspect any misuse, you are to inform us
            immediately by sending an email to info@pavi.com.mt.
            If you are concerned that the confidentiality of your password has been compromised, please change
            it immediately and let us know.
            Control or use of your account and associated credentials may not be assigned, leased, novated or in
            any manner transferred or sold to a third party. In such an event, we reserve the right to block access
            to your account and to take action as we may deem fit in terms of law.<br /><br />
            Acceptable Use<br /><br />
            When accessing or using this Website, you agree that you will not violate any law, contract, intellectual
            property or other rights, and that you are solely responsible for your own conduct. Without limiting
            the generality of the foregoing, you agree that you will not:<br />
            - Use the Website in any manner that could interfere with, disrupt, negatively affect or inhibit
            other users from fully enjoying the Website, or that could damage, disable, overburden or
            impair the functioning of said Website in any manner;<br />
            - Use the Website to fund, support, or otherwise engage in any illegal gambling activities, fraud,
            money laundering or terrorist activities or other illegal activities;<br />
            - Without our prior written consent, use any robot, spider, crawler, scraper or other automated
            means or interface not provided by us, to access the Website or to extract data or information
            therefrom;<br />
            - Without our prior written consent, and whether by automated means or otherwise, extract
            data or information from the Website, including price and product information, for inclusion
            onto a price comparison platform or similar;<br />
            - Without our prior written consent, reproduce, copy, distribute or in any manner use this
            Website or any part thereof, for commercial purposes;<br />
            - Without our prior written consent, develop any third-party applications that interact with our
            Website;<br />
            - Provide false, inaccurate or misleading information;<br />
            - Use the Website to cause any form of damage or disrepute to us;<br />
            - Use the Website in any manner that violates these Terms and Conditions.<br /><br />
            Intellectual Property<br /><br />
            For the purposes of this section, ‘Intellectual Property’ shall include, without limitation, and whether
            registered, unregistered or registrable in Malta or elsewhere, any trademark, tradename, logo, design,
            mark, industrial design, copyright or copyrightable material, patent, rights in databases, databases,
            graphics, software, source code, graphical user interface, internet domain name, website address,
            mask work, trade secret, knowhow, technical data, market data or specifications.
            Any and all Intellectual Property related in any manner whatsoever to the Website is the sole and
            exclusive property of the relevant PG Group company that owns it, and is subject to protection under
            the applicable law.
            The user is prohibited from copying, editing, amending, distributing, displaying, modifying, adapting,
            sub-licensing, emulating, reverse-engineering, decompiling, disassembling, combining, processing,
            encoding, translating, selling or in any other way using any of the Intellectual Property without the
            consent of its owner.<br /><br />
            Third-Party Content<br /><br />
            In using our Website, you may view content provided by third parties, including, potentially, links to
            webpages pertaining to such third parties (the “Third-Party Content”). We do not control or adopt any
            Third-Party Content and shall thus have no responsibility for Third-Party Content, including, without
            limitation, material that may be misleading, incomplete, erroneous, offensive, indecent or otherwise
            objectionable.<br /><br />
            Site Content<br /><br />
            You should not rely on this material or information on this Website as a basis for making any business,
            legal or any other decisions. Whilst we endeavour to keep the information up to date and correct, we
            make no representations or warranties of any kind, express or implied, about the completeness,
            accuracy, reliability or availability with respect to the Website or the information, products, services or
            related graphics contained on the Website for any purpose. Any reliance you place on such material is
            at your own risk.<br /><br />
            Prices<br /><br />
            Without prejudice to the section above entitled, ‘Site Content’, all prices shown on our Website are in
            the Euro (€) currency and are inclusive of Value Added Tax or any other sales tax as applicable in
            accordance with Maltese law from time to time. Although all reasonable efforts are made by Us to
            ensure that all prices displayed are accurate and up-to-date, errors and inaccuracies may occur.
            We reserve the right to vary our prices at any time.<br /><br />
            Site Availability<br /><br />
            Although we do our utmost to maintain the Website as fault free as possible, no guarantee can be
            provided as to its continued use and availability.
            Should any error or fault occur, kindly contact +356 22700336.
            Furthermore, we reserve the right to restrict your access to the Website due to maintenance or repair
            procedures, uploads and updates.<br /><br />
            Liability<br /><br />
            To the maximum extent permitted by law, we shall not be liable, in any event, for any direct damages,
            losses or liabilities whatsoever incurred or suffered by you or any third party as a result of or in
            connection with any of the following:<br />
            i. Any loss of credentials or loss of access to your account through no fault of our own;<br />
            ii. Any instructions received from your end that contain incorrect, missing or improperly
            formatted information;<br />
            iii. Any unforeseen circumstances or circumstances outside of our control preventing the
            proper performance of the Website, including any event of force majeure, such as:
            downtime of the Website or reduced service availability, any interruption or failure of
            service, as well as, generally, any disruptions brought about as a result of technological
            failings, including any such failings arising from the use of the internet and its associated
            risks;<br />
            iv. Any use by you of any information contained on the Website; and, or<br />
            v. Any Third-Party Content.<br />
            In addition, and to the maximum extent permitted by law, we shall not be liable for any indirect
            damages, losses or liabilities whatsoever incurred or suffered by you or any third party, including,
            without limitation, damages for loss of profits, loss of information, business interruption, or loss of
            revenue or goodwill, as a result of either of the above instances (i)-(v) and, or any other claims or
            allegations relating to / made against us or any of our officers, directors, shareholders, partners,
            employees and agents.<br /><br />
            Indemnity<br /><br />
            To the maximum extent permitted by law, you agree to defend, indemnify and hold us, together with
            each of our officers, directors, shareholders, partners, employees and agents, harmless from and
            against any claims, actions, loss, liabilities, expenses, costs or demands, including any legal fees, arising
            as a direct or indirect result of your breach of these Terms and Conditions.
            Cancellation of Services / Your Account
            We reserve the right to cancel any of our services and suspend and, or terminate your account at any
            point in time should you breach any of these Terms and Conditions.
            Cancellations, Returns and Refunds
            All non-perishable items1 which are purchased by a consumer through the Website may be returned
            to either PAVI or PAMA outlets within 14 days from when the consumer has communicated his decision
            to cancel the purchase to Us; provided that in order to avail themselves of this right, consumers must
            provide Us with evidence that any such item was purchased from Us.
            The consumer shall be deemed to have effectively communicated his/her decision either by sending
            an email to XX@XXXX.com or by using the ‘withdrawal form’ annexed as ‘Document A’ to these Terms
            and Conditions. In any case, the consumer shall communicate his decision to cancel the purchase
            within 14 days from the date of delivery.<br /><br />
            1 Excluding sealed goods (such as certain cosmetic products, toothbrushes, shavers, etc) which are not suitable
            for return due to health protection or hygiene reasons and which were unsealed after delivery.<br /><br />
            A full refund2 will be given to the consumer within 14 days from receiving the cancellation request,
            saving our right to delay the refund if We have not received the items or evidence of return. The
            consumer shall bear the direct cost of returning the item/s.
            For the avoidance of any doubt, non-perishable items which are purchased from either Pavi or Pama
            outlets cannot be returned unless the item/s are faulty and provided that a receipt is furnished upon
            return.
            Perishable items, which includes but is not limited to foods and beverages with short expiry time limits
            (including those to be kept refrigerated), whether purchased in-store or through the Website, cannot
            be returned.<br /><br />
            Amendments<br /><br />
            We reserve the right to make any changes to these Terms and Conditions at any given time without
            incurring any liability towards you or any third party. Any such updated Terms and Conditions shall
            become immediately applicable and enforceable upon publication on the Website and shall supersede
            any previously published Terms and Conditions.
            It is your responsibility to periodically check these Terms and Conditions for any changes, and your
            continued use of or access to the Website following the publication of any amendments will constitute
            acceptance of those changes.
            Should we be required to obtain your consent or to provide you with prior notice in respect of any
            such changes, we will do so.<br /><br />
            Severability<br /><br />
            In the event that any provision, or part thereof, of these Terms and Conditions is found by a competent
            adjudicatory body or court of law to be invalid, unlawful or unenforceable to any extent, then such
            provision, or part thereof, shall be severed from the remainder of these Terms and Conditions, which
            shall continue to be valid and enforceable to the fullest extent permitted by law.<br /><br />
            Applicable law<br /><br />
            These Terms and Conditions shall be governed by and construed in accordance with the Laws of Malta.
            Any disputes which may arise between you and us in relation to these Terms and Conditions shall be
            subject to the exclusive jurisdiction of the Maltese courts.<br /><br />
            Enquiries<br /><br />
            Should you have any questions, wish to make any complaints, or simply feel the need to provide us
            with any feedback, please feel free to call us on +356 22700336 and, or send an email to
            info@pavi.com.m.<br /><br />
            2 Including any costs for delivery, unless the consumer had expressly opted for express delivery.<br /><br />

            Document A<br /><br />
            To Pavi Supermarkets Limited (C-29878) and Pama Supermarket Limited (C-67445), both having their
            registered address situated at PG Group Head Offices, Pama Shopping Village, Valletta Road, Mosta
            MST9017, Malta, [xxxxx]<br />
            I/We hereby give notice that I/We withdraw from my/our contract of sale of the following goods,<br />
            Received on ____________,<br />
            Name of consumer(s): ____________________,<br />
            Address of consumer(s): ____________________,<br />
            Signature of consumer(s) (only if this form is notified on paper) ______________________,<br />
            Date: ______________________.<br />
        </div>
    )
}

export default TermsAndCondition