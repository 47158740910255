import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import config from '../../config/config';
import axios from 'axios';
import { authHeader } from '../../utils/authHeader';

export const profileService = { points };

function points() {
    return (
        axios.get(`${config.baseUrl}/cli/profiles/point-balance`,
        {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}