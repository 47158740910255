import React, { Component } from "react"
import SignupBanner from "../../images/signup-banner.png"
import SignupCheck from "./SignupCheck/SignupCheck"
import SignupChoose from "./SignupChoose/SignupChoose"
import SignupStep2 from "./SignupSteps/SignupStep2"
import SignupStep3 from "./SignupSteps/SignupStep3"
// import SignupStep4 from "./SignupSteps/SignupStep4"
import SignupSuccessPopupModal from "./SignupSuccessPopupModal"
import MissingEmailModal from "./MissingEmailModal"
import toaster from "../../utils/toaster"
import swal from "../../utils/swal";
import { authenticationService } from "../../services/basis/AuthenticationService"
import { Translation } from "react-i18next"
export default class Signup extends Component {
    state = {
        step: 0,
        type: null,
        data: null,
        show: false,
        showEmailError: false,
        emailField: false
    }

    setType = (type) => {
        this.setState({
            type: type,
            step: 1
        })
    }

    setShow = (showPopup) => {
        this.setState({ show: showPopup })
    }

    nextStep = data => {
        this.setState({
            data: { ...this.state.data, ...data },
            step: this.state.step + 1
        })
    }

    previousStep = (data) => {
        console.log(data);
        if (this.state.step === 1 && window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ step: 0 }));
        }
        this.setState({
            data: { ...this.state.data, ...data },
            step: this.state.step - 1,
            emailField: this.state.step === 1 ? false : this.state.emailField
        })
    }

    signupCheck = data => {
        authenticationService.signupCheck(data)
            .then(response => {
                // Show success popup
                this.setShow(true)
            }).catch((error) => {
                if (error.errorCode === 44) {
                    this.setState({ showEmailError: true })
                } else {
                    toaster.error(error.message);
                }
            })
    }

    onSubmit = data => {
        const signupData = { ...this.state.data, ...data };
        authenticationService.signup(signupData, this.state.type)
            .then(response => {
                console.log(response);
                this.setState({
                    data: signupData,
                    show: true
                })
            }).catch((error) => {
                if (error.errorCode === 13) {
                    swal.error("Password not valid");
                } else {
                    swal.error(error.message);
                }
            })
    }

    render() {
        const { data, step, type, show, showEmailError, emailField } = this.state;
        return (
            <Translation>
                {t =>
                    <div className="signup">
                        <div className="signup-banner" style={{ backgroundImage: `url(${SignupBanner})` }} alt="">
                            <div className="text text-center">
                                <span>{t("signup.newAccount")}</span>
                            </div>
                        </div>
                        {step !== 0 &&
                            <>
                                <div className="signup-title">
                                    {type === "fetch" ? t("signup.withFidelity") : t("signup.withoutFidelity")}
                                </div>
                                {type === "check" &&
                                    <div className="checkout-header">
                                        <div className="d-flex justify-content-between checkout-controls">
                                            <span
                                                className={`${step === 1 ?
                                                    "checkout-controls-step-current" : "checkout-controls-step-completed"} 
                                        checkout-controls-step`}>
                                                {t("signup.header.registry")}
                                            </span>
                                            <span
                                                className={`${step === 2 ?
                                                    "checkout-controls-step-current" : "checkout-controls-step-completed"} 
                                        checkout-controls-step`}>
                                                {t("signup.header.addresses")}
                                            </span>
                                            <span
                                                className={`${step === 3 ?
                                                    "checkout-controls-step-current" : "checkout-controls-step-completed"} 
                                        checkout-controls-step`}>
                                                {t("signup.header.account")}
                                            </span>
                                            {/* <span
                                                className={`${step === 4 ?
                                                    "checkout-controls-step-current" : "checkout-controls-step-completed"} 
                                        checkout-controls-step`}>
                                                {t("signup.header.consents")}
                                            </span> */}
                                        </div>
                                    </div>
                                }
                            </>
                        }
                        {step === 0 && <SignupChoose setType={this.setType} />}
                        {step === 1 && <SignupCheck
                            data={data}
                            type={type}
                            signupCheck={this.signupCheck}
                            nextStep={this.nextStep}
                            previousStep={this.previousStep}
                            emailField={emailField}
                        />}
                        {step === 2 && <SignupStep2 data={data} type={type} nextStep={this.nextStep} previousStep={this.previousStep} />}
                        {step === 3 && <SignupStep3 data={data} signup={this.onSubmit} previousStep={this.previousStep} />}
                        {/* {step === 4 && <SignupStep4 data={data} signup={this.onSubmit} previousStep={this.previousStep} />} */}
                        <SignupSuccessPopupModal show={show} setShow={this.setShow} />
                        <MissingEmailModal
                            show={showEmailError}
                            setShow={showPopup => this.setState({ showEmailError: showPopup })}
                            showEmailField={() => this.setState({ emailField: true })}
                        />
                    </div>
                }
            </Translation>
        );
    }
}