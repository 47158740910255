import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import config from '../../config/config';
import { authHeader } from "../../utils/authHeader";
import axios from 'axios';

export const couponsService = { getCoupons, activeCoupon };

function getCoupons() {
    return (
        axios.get(`${config.baseUrl}/cli/coupons`, {
            headers: authHeader()
        })
            .then(handleResponse)
        // .then(checkResponse)
    );
}

function activeCoupon(couponId) {
    return (
        axios.post(`${config.baseUrl}/cli/coupons/${couponId}`, {},
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    )
}