import config from '../../config/config';
import { authHeader } from '../../utils/authHeader';
import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import { format } from "date-fns";
import axios from "axios"

export const authenticationService = { login, logout, signup, isLogged, updateUser, signupCheck, updateFavouriteStore };

// function login(data) {
//     let headers = new Headers();
//     headers.set('Authorization', 'Basic ' + btoa(data.username + ":" + data.password));
//     return (
//         fetch(`${config.baseUrl}/cli/profiles/login`, {
//             method: 'GET',
//             headers: headers,
//             mode: "cors",
//             credentials: 'include'
//         })
//             .then(handleResponse)
//             .then(checkResponse)
//             .then((responseData) => {
//                 localStorage.setItem('user', JSON.stringify(responseData.data));
//                 return responseData;
//             })
//     );
// }

function login(data) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/login`,
            {
                username: data.username,
                password: data.password,
            })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                console.log(responseData);
                return responseData;
            })
    )
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user")
    return (
        axios.post(`${config.baseUrl}/cli/profiles/logout`,
            {},
            {
                headers: authHeader()
            })
            .then(handleResponse)
        // .then(checkResponse)
    );
}

function signup(data) {
    let body = {
        username: data.username,
        password: data.password,
        profile: {
            firstName: data.firstName,
            lastName: data.lastName,
            birthDate: data.birthDate,
            mobilePhone: data.mobilePhone,
            mobilePhonePrefix: data.mobilePhonePrefix,
            homePhone: data.homePhone,
            homePhonePrefix: data.homePhonePrefix,
            email: data.username,
            gender: data.gender,
            cards: (data.code && data.code !== null && data.code !== "") ?
                [{
                    code: data.code,
                    principal: true,
                    enabled: true
                }]
                : null,
            address: {
                address: data.address,
                state: data.state,
                city: data.city,
                county: data.county,
                zip: data.zip,
                streetNumber: data.streetNumber,
                nation: data.nation
            },
            accept1: data.accept1,
            // accept2: data.accept2,
            accept2: true,
            accept3: data.accept3,
            accept4: data.accept4,
        }
    }
    return (
        axios.post(`${config.baseUrl}/cli/profiles/signup`,
            body
        )
            .then(handleResponse)
            .then(checkResponse)
    );
}

function isLogged() {
    return (
        axios.get(`${config.baseUrl}/cli/profiles/login`, {
            headers: authHeader(),
        })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                localStorage.setItem("user", JSON.stringify(responseData.data))
                return responseData;
            })
    );
}

function updateUser(data) {
    console.log(data);
    return (
        axios.put(`${config.baseUrl}/cli/profiles`,
            {
                firstName: data.firstName,
                lastName: data.lastName,
                mobilePhone: data.mobilePhone,
                mobilePhonePrefix: data.mobilePhonePrefix,
                homePhone: data.homePhone,
                homePhonePrefix: data.homePhonePrefix,
                ssnCode: data.ssnCode,
                // birthDate: data.birthDate,
                cards: [{
                    code: data.profileCard,
                    principal: true,
                    enabled: true
                }],
                // email: data.email,
                // gender: data.gender,
                accept1: data.accept1,
                accept2: data.accept2,
                accept3: data.accept3,
                accept4: data.accept4,
            },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                localStorage.setItem("user", JSON.stringify(responseData.data))
                return responseData;
            })
    );
}

function updateFavouriteStore(storeId) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/preferences/store`, {
            storeId
        },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function signupCheck(data) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/signupcheck`, {
            cardNumber: data.cardNumber,
            documentId: data.documentId,
            email: data.email
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}
