import React from 'react';
import { ReactComponent as ProfileIncomplete } from '../../images/profile-incomplete.svg';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import "./signup.styles.scss";

function MissingEmailModal(props) {

    const { t } = useTranslation();

    const handleClose = () => {
        props.showEmailField();
        props.setShow(false);
    }

    return (
        <>
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header>
                </Modal.Header>
                <div className="modal-popup profile-incomplete d-flex flex-column align-items-center">
                    <ProfileIncomplete className="" />
                    <div className="modal-popup-heading">
                        {/* {t("signup.success.title")} */}
                        Your profile is incomplete
                    </div>
                    <div>
                        <p className="modal-popup-text">
                            {/* {t("signup.success.text")} */}
                            Complete your profile by entering a valid email address
                        </p>
                    </div>
                    <div className="modal-popup-btn-div">
                        <button className="cta" onClick={handleClose}>
                            {/* <span>{t("signup.success.back")}</span> */}
                            <span>Continue</span>
                        </button>
                    </div>
                </div>
            </Modal>

        </>
    );
}

export default MissingEmailModal;