import config from "../../config/config"
import { handleResponse } from "../../utils/handleResponse";
import { checkResponse } from "../../utils/checkResponse";
import axios from 'axios';

export default function resetPassword(email) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/password`, 
        {
            tid: "01",
            email: email
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}