import React from "react"
import { storesService } from "../../../services/basis/StoresService"
import Dropdown from "../../../components/UI/Dropdown/Dropdown";

export default class CartCreateSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stores: [],
        }
    }

    componentDidMount() {
        const { deliveryMode } = this.props;
        storesService.storesByDelivery(deliveryMode)
            .then(data => {
                this.setState({ stores: data, });
            });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.state !== nextProps.state ||
            this.props.deliveryMode !== nextProps.deliveryMode) {
            storesService.storesByDelivery(nextProps.state, nextProps.deliveryMode)
                .then(data => {
                    this.setState({ stores: data, });
                });
        }
    }

    render() {
        const { select, label } = this.props;
        const { stores } = this.state;
        if (stores) {
            return (<Dropdown items={stores} select={select} label={label} />);
        } else
            return null;
    }
}

