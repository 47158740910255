import React from "react";
import { Link, withRouter } from "react-router-dom"
import { Translation } from 'react-i18next';
// import Logo from "../../../../images/logo-smartbip.png";
import Logo from "../../../../images/PAVI-PAMA-LOGO-blue.png";
import HeaderItem from "../HeaderItems/HeaderItem";
import HeaderCartItem from "../HeaderItems/HeaderCartItem";
import config from "../../../../config/config";
// import { ReactComponent as ShopIcon } from '../../../../images/shop.svg';
// import { ReactComponent as PinIcon } from '../../../../images/pin.svg';
import { ReactComponent as UserIcon } from '../../../../images/landing-user-sticky.svg';
import { ReactComponent as CartIcon } from '../../../../images/landing-cart-sticky.svg';
import { ReactComponent as CategoryIcon } from '../../../../images/category.svg';
import { ReactComponent as LeafletsIcon } from '../../../../images/leaflets.svg';

import "./mobile-header.styles.scss";

class MobileHeader extends React.Component {

    updateCart = () => {
        if (this.props.user && config.SHOW_CART_UPDATE) {
            return (
                <Link to="/cart/update">
                    <nobr>
                        <span className="pin-link">{this.props.cart && this.props.cart.storeDescription ?
                            this.props.cart.storeDescription : config.DEFAULT_STORE_DESCRIPTION}</span>
                    </nobr>
                </Link>
            );
        } else {
            return (
                <nobr>
                    <span className="pin-link">{this.props.cart && this.props.cart.storeDescription ?
                        this.props.cart.storeDescription : config.DEFAULT_STORE_DESCRIPTION}</span>
                </nobr>
            );
        }
    }

    render() {
        const { user, cart, searchInput, onChange, closeAll, showModalLogin } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="mobile-header">
                        <div className="mobile-header-wrapper">
                            <div className="container-fluid">

                                <div className="row mobile-header-top">
                                    {/* <div className="col-8 pin">
                                        <PinIcon />
                                        {this.updateCart()}
                                    </div> */}
                                    {/* <div className="col-4 text-right">
                                        {user ?
                                            <div className="col user">
                                                <div className="user-logged-container" onClick={this.props.loginModal}>
                                                    <div className="user-logged">
                                                        {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="col user text-right">
                                                <UserIcon onClick={showModalLogin} />
                                            </div>
                                        }
                                    </div> */}
                                    {/* <div className="col-4 shop text-right">
                                        <Link className="link" to="/stores" onClick={closeAll}>
                                            <ShopIcon />
                                            <span className="shop-link">{t("stores")}</span>
                                        </Link>
                                    </div> */}
                                </div>

                                <div className="row mobile-header-bottom">
                                    <div className="col-4">
                                        <div className="logo align-items-center">
                                            <Link to="" onClick={closeAll}>
                                                <img src={Logo} alt="" />
                                                {/* <Logo /> */}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="icons">
                                            <HeaderItem
                                                actived={this.props.history.location.pathname.includes("/leaflets")}
                                                onClick={() => this.props.history.push("/leaflets")}>
                                                <LeafletsIcon />
                                            </HeaderItem>
                                            <HeaderItem
                                                onClick={this.props.categoriesModal}
                                                actived={this.props.showCategories}>
                                                <CategoryIcon />
                                            </HeaderItem>
                                            {user ?
                                                <HeaderItem
                                                    onClick={this.props.loginModal}
                                                    actived={this.props.showLogin}>
                                                    <div className="user-logged">
                                                        {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                                                    </div>
                                                </HeaderItem>
                                                :
                                                <HeaderItem
                                                    onClick={showModalLogin}
                                                    actived={this.props.showLogin}>
                                                    <UserIcon />
                                                </HeaderItem>
                                            }
                                            {user &&
                                                <HeaderCartItem
                                                    actived={this.props.showCategories}
                                                    path="/cart"
                                                    total={this.props.renderTotal(cart)}
                                                    items={cart ? cart.itemsCount : "0"}
                                                    closeAll={this.props.closeAll}>
                                                    <CartIcon />
                                                </HeaderCartItem>
                                            }
                                            {!user &&
                                                <HeaderItem
                                                    onClick={showModalLogin}>
                                                    <CartIcon />
                                                </HeaderItem>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {(this.props.location.pathname === "/" ||
                                this.props.location.pathname === "/category" ||
                                this.props.location.pathname === "/search" ||
                                this.props.location.pathname === "/cart" ||
                                this.props.location.pathname.includes("/store") ||
                                this.props.location.pathname.includes("/product")
                            ) &&
                                <div className="mobile-header-search">
                                    <form onSubmit={e => {
                                        e.preventDefault();
                                        this.props.history.push({
                                            pathname: "/search",
                                            state: { searchInput: searchInput }
                                        })
                                        closeAll();
                                    }}>
                                        <input type="search" onChange={onChange} value={searchInput}
                                            placeholder={t("header.mobileMobilePlaceHolder")}></input>
                                        <button type="submit" className="cta">
                                            <span>{t("search")}</span>
                                        </button>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                }
            </Translation>
        )
    }
}

export default withRouter(MobileHeader)