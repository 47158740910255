import React from "react";
import Modal from "../../UI/Modal/Modal"
import Login from "../../../features/Login/Login"
import Categories from "../../../features/Categories/Categories";
import NewHeader from "./NewHeader/NewHeader";
import MobileHeader from "./MobileHeader/MobileHeader"
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions"
import { ecommerceService } from "../../../services/ecommerce/EcommerceService";

class ResponsiveHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLogin: false,
            showCategories: false,

            searchInput: "",
            category: "",
            order: null
        };
    }

    componentDidMount() {
        if (this.props.user) {
            ecommerceService.orders(0)
                .then(response => {
                    if (response.data.length > 0) {
                        this.setState({
                            order: response.data[0],
                        })
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }
        // const path = "html/body/div[@id='root']/div/div/div/div/div/div/div[2]/div[2]/form/input";
        // const input = document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
        // input.addEventListener("keydown", this.clickInput);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.key !== prevProps.location.key
            && this.props.location.pathname !== "/search") {
            this.setState({ searchInput: "" })
        }
    }

    onChange = (e) => this.setState({ searchInput: e.target.value });

    setCategory = (item) => this.setState({ category: item })

    closeAll = () => this.setState({
        showLogin: false,
        showAvailability: false,
        showSearch: false,
        showCategories: false,
    })

    loginModal = () => this.setState({
        showLogin: !this.state.showLogin,
        showAvailability: false,
        showSearch: false,
        showCategories: false,
    })

    categoriesModal = () => this.setState({
        showCategories: !this.state.showCategories,
        showLogin: false,
        showAvailability: false,
        showSearch: false,
    })

    submitSearch = (e) => {
        e.preventDefault();
        console.log(this.state);
        const path = "html/body/div[@id='root']/div/div/div/div/div/div/div[2]/div[2]/form/input";
        const input = document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
        this.setState({
            searchInput: input.value
        }, () => {
            this.props.history.push({
                pathname: "/search",
                state: { searchInput: input.value, categoryId: this.state.category ? this.state.category.id : "" }
            })
            this.closeAll();
        });
    }

    renderTotal = (cart) => {
        if (cart) {
            if (cart.netTotal) {
                return `${cart.netTotal.toFixed(2)} \u20AC`;
            } else {
                return `0.00 \u20AC`;
            }
        } else {
            return `0.00 \u20AC`;
        }
    }

    render() {
        const { user, showModalLogin } = this.props;
        // const path = "html/body/div[@id='root']/div/div/div/div/div/div/div[2]/div[2]/form/input";
        // console.log(document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue);
        return (
            !location.pathname.includes("/app/share") &&
            <>
                <div className="d-none d-lg-block">
                    <NewHeader
                        {...this.props}
                        closeAll={this.closeAll}
                        loginModal={this.loginModal}
                        categoriesModal={this.categoriesModal}

                        renderTotal={this.renderTotal}

                        showCategories={this.state.showCategories}
                        showLogin={this.state.showLogin}

                        searchInput={this.state.searchInput}
                        onChange={this.onChange}

                        availability={this.props.availability}

                        showModalLogin={showModalLogin}
                        order={this.state.order}
                        submitSearch={this.submitSearch}
                        setCategory={this.setCategory}
                    />
                </div>

                <div className="d-lg-none">
                    {!window.location.pathname.includes("/leaflets/") &&
                        <MobileHeader
                            {...this.props}
                            closeAll={this.closeAll}
                            loginModal={this.loginModal}
                            categoriesModal={this.categoriesModal}

                            renderTotal={this.renderTotal}

                            showCategories={this.state.showCategories}
                            showLogin={this.state.showLogin}

                            searchInput={this.state.searchInput}
                            onChange={this.onChange}

                            showModalLogin={showModalLogin}
                            submitSearch={this.submitSearch}
                        />
                    }
                </div>
                {
                    this.state.showLogin &&
                    <Modal show={this.state.showLogin} close={this.loginModal}>
                        <Login user={user} closeAll={this.closeAll} />
                    </Modal>
                }
                {
                    this.state.showCategories &&
                    <Modal show={this.state.showCategories} close={this.categoriesModal}>
                        <Categories closeAll={this.closeAll} />
                    </Modal>
                }
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        availability: state.cart.availability,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showModalLogin: () => dispatch(actionCreators.showLogin(true)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResponsiveHeader));
