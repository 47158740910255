import React, { useState } from "react"
import { Translation } from "react-i18next";
import { ReactComponent as HidePassword } from "../../../images/Hide-Password.svg"
import { ReactComponent as ShowPassword } from "../../../images/Show-Password.svg"

import "./validate-input.styles.scss";

const PasswordInput = ({ value, watch, label, register, errors, ...otherProps }) => {

    const [showPassword, setShowPassword] = useState();

    const filterInput = (event) => {
        if (otherProps.pattern && event.key !== 'Backspace') {
            const regex = RegExp(otherProps.pattern);
            if (!regex.test(event.key)) {
                event.preventDefault();
            }
        }
    }
    const transformInput = (event) => {
        if (otherProps.uppercase) {
            event.target.value = event.target.value.toUpperCase();
        }
    }
    return (
        <Translation>
            {t =>
                <div className="group">
                    <div className="input-container">
                        <input className={`form-input ${errors ? "input-required" : ""}`}
                            onKeyDown={filterInput}
                            onChange={transformInput}
                            defaultValue={value}
                            ref={register}
                            {...otherProps}
                            type={showPassword ? "text" : "password"}
                        />
                        <span className="icon" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <HidePassword /> : <ShowPassword />}
                        </span>
                    </div>
                    {
                        label ?
                            (<label className={`${(watch && watch.length > 0) ||
                                (value && value.length > 0 && watch === undefined) ? "shrink" : ""} form-input-label`}>
                                {t(label)}
                            </label>)
                            : null
                    }
                </div>
            }
        </Translation>
    );
}

export default PasswordInput;