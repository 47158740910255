import config from "../../config/config"
import { handleResponse } from "../../utils/handleResponse";
import { checkResponse } from "../../utils/checkResponse";
import { authHeader } from "../../utils/authHeader";
import axios from 'axios';

export const paymentsService = {
    paymenthMethods, removePaymentMethod
}

function paymenthMethods() {
    return (
        axios.get(`${config.baseUrl}/cli/pay/paymentmethod`, {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function removePaymentMethod() {
    return (
        axios.post(`${config.baseUrl}/cli/pay/paymentmethod/delete`, 
        {
            paymentMethodType: "STRIPE"
        },
        {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}