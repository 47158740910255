import config from "../../config/config"
import { authHeader } from "../../utils/authHeader";
import axios from 'axios';
import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';

export const smartlistService = {
    addFavorite, getFavorites, deleteFavorite, getSlists, itemsCreate, itemsCreateCustom,
    fetchSlist, itemsDelete, itemsUpdate, createSlist, deleteSlist, renameSlist, copySlist
}

function addFavorite(barcode) {
    return (
        axios.post(`${config.baseUrl}/cli/slists/products/favorite/add`,
            {
                barcode: barcode,
            },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function deleteFavorite(barcode) {
    return (
        axios.post(`${config.baseUrl}/cli/slists/products/favorite/delete`,
            {
                barcode: barcode,
            },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function getFavorites() {
    return (
        axios.get(`${config.baseUrl}/cli/slists/products/favorite/all`, {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function getSlists() {
    return (
        axios.get(`${config.baseUrl}/cli/slists`, {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function itemsCreate(listId, barcode, amount) {
    return (
        axios.post(`${config.baseUrl}/cli/slists/items/create`,
            {
                id: listId,
                barcode: barcode,
                amount: amount
            },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function itemsCreateCustom(listId, amount, description) {
    return (
        axios.post(`${config.baseUrl}/cli/slists/items/create`,
            {
                id: listId,
                type: "CUSTOM",
                amount: amount,
                description: description
            },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function fetchSlist(id) {
    return (
        axios.get(`${config.baseUrl}/cli/slists/${id}`, {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function itemsDelete(listId, itemId) {
    return (
        axios.post(`${config.baseUrl}/cli/slists/items/delete`,
            {
                id: listId,
                itemId: itemId,
            },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function itemsUpdate(listId, itemId, amount) {
    return (
        axios.post(`${config.baseUrl}/cli/slists/items/update`,
            {
                id: listId,
                itemId: itemId,
                amount: amount
            },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function createSlist(title) {
    return (
        axios.post(`${config.baseUrl}/cli/slists/create`,
            {
                title: title
            },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function deleteSlist(id) {
    return (
        axios.post(`${config.baseUrl}/cli/slists/delete`,
            {
                id: id
            },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function renameSlist(id, title) {
    return (
        axios.post(`${config.baseUrl}/cli/slists/rename`,
            {
                id: id,
                title: title
            },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function copySlist(id, title) {
    return (
        axios.post(`${config.baseUrl}/cli/slists/copy`,
            {
                id: id,
                title: title
            },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

