import React from "react";
import Carousel from 'react-bootstrap/Carousel'
import banners from "./Service"
export default class HomeCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            banners: []
        };
    }

    componentDidMount() {
        banners().then(response => {
            this.setState({
                isLoaded: true,
                banners: response.data
            })
        });
    }

    render() {
        const { isLoaded, banners } = this.state;
        return (isLoaded ?
            <>
                <div className="desktop">
                    <Carousel className="Carousel">
                        {banners.map((item, index) => (
                            <Carousel.Item key={item.id} >
                                <div style={{ backgroundImage: `url(${item.imageUrl})` }} alt=""></div>
                            </Carousel.Item>
                        ))}
                    </Carousel>

                </div>
                <div className="mobile">
                    <Carousel className="Carousel">
                        {banners.map((item, index) => (
                            <Carousel.Item key={item.id}>
                                <div style={{ backgroundImage: `url(${item.imageApp})` }} alt=""></div>
                            </Carousel.Item>
                        ))}
                    </Carousel>

                </div>
            </>

            : null
        );
    }

}