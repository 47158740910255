import config from "../../../../config/config"
import { handleResponse } from "../../../../utils/handleResponse";
import { checkResponse } from "../../../../utils/checkResponse";
import { authHeader } from "../../../../utils/authHeader";
import axios from 'axios';

export default function timeslots(cartId, day) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/slots/${cartId}?weekDay=${day}`, {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}