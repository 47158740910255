import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from 'react-bootstrap/Form'
import CustomInput from "../../../components/UI/Input/CustomInput";
import { Translation } from 'react-i18next';
import { Redirect } from "react-router-dom";
import Summary from "../Summary/Summary";
// import BillingAddressSelect from "./BillingAddressSelect/BillingAddressSelect";
// import { addressesService } from "../../ProfileContainer/ProfileAddresses/Service"
// import toaster from "../../../utils/toaster";
// import i18n from "../../../i18n"
// import BillingAddressPopup from "../../ProfileContainer/ProfileAddresses/AddressPopup/BillingAddressPopup"

export default class UserData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addresses: [],
    };
  }

  // componentDidMount() {
  //   addressesService.getBillingAddresses()
  //     .then(response => {
  //       if (response.data.length > 0) {
  //         this.setState({
  //           addresses: response.data,
  //         })
  //       }
  //     }).catch((error) => {
  //       console.log(error);
  //     })
  // }

  // newAddress = (address) => {
  //   addressesService.newBillingAddress(address)
  //     .then(response => {
  //       this.setState({
  //         addresses: response.data,
  //       })
  //       toaster.standard(i18n.t("billing.addresses.new-message")) 
  //     }).catch(error => {
  //       console.log(error);
  //     })
  // }

  render() {
    const { timeSlotId, cart, freeze, billingAddress,
      enableProductsReplacement, enableReplacement, paymentMethod, setPaymentMethod, gift, setGift } = this.props;
    // const { addresses } = this.state;

    if (!timeSlotId) {
      return <Redirect to='/checkout' />;
    }

    return (
      <Translation>
        {t =>
          <div className="checkout-data">
            <div className="checkout-data-title">{t('signup.confirmData')}</div>
            <Row className="signup-row">
              <Col className="pb-3" md={6}>
                <CustomInput
                  label={"profileEmail"}
                  value={this.props.profileEmail}
                  onChange={this.props.selectProfileInfo}
                  type="email"
                />
              </Col>
              <Col className="pb-3" md={6}>
                <CustomInput
                  label={"profileMobilePhone"}
                  value={this.props.profileMobilePhone}
                  onChange={this.props.selectProfileInfo}
                  type="tel"
                />
              </Col>
            </Row>
            <Row className="signup-row">
              <Col className="pb-3" md={6}>
                <CustomInput
                  label={"profileFirstName"}
                  value={this.props.profileFirstName}
                  onChange={this.props.selectProfileInfo}
                  type="text"
                />
              </Col>
              <Col className="pb-3" md={6}>
                <CustomInput
                  label={"profileLastName"}
                  value={this.props.profileLastName}
                  onChange={this.props.selectProfileInfo}
                  type="text"
                />
              </Col>
            </Row>
            {cart && cart.deliveryMode === "AT_HOME" &&
              <Row className="signup-row">
                <Col className="pb-3">
                  <CustomInput
                    label={t("cartCreate.deliveryAddress")}
                    disabled={true}
                    value={cart && cart.delivery && cart.delivery.addressDescription}
                    type="email"
                  />
                </Col>
              </Row>
            }

            <hr />

            <div className="checkout-data-title">{t("userData.deliveryNote")}</div>

            <Form.Control
              as="textarea"
              name="notes"
              className="signup-textarea checkout-textarea w-100"
              rows="4"
              onChange={this.props.selectProfileInfo}
              value={this.props.notes}
            />

            {/* <hr />

            <div className="custom-control custom-checkbox">
              <input className="custom-control-input" type="checkbox" id="invoice" name="invoice"
                checked={invoice}
                onChange={selectInvoice}
              />
              <label style={{ marginBottom: "20px" }} className="custom-control-label" htmlFor="invoice">
                {t("userData.invoice")}
              </label>
            </div>

            {invoice &&
              <>
                <div className="address-container">
                <div style={{ marginBottom: "unset" }} className="checkout-data-title">{t("userData.invoiceAddress")}</div>
                  <BillingAddressPopup
                    action={this.newAddress}
                    title={t("addresses.new")}
                    button={t("save")}
                    isUpdate={false}
                    trigger={
                      <span className="add-link">{t("userData.addInvoiceAddress")}</span>
                    }
                  />
                </div>
                {addresses && addresses.map(address => (
                  <BillingAddressSelect
                    key={address.id}
                    address={address}
                    billingAddressId={this.props.billingAddressId}
                    selectAddress={this.props.selectAddress}
                  />
                ))}
              </>
            } */}

            <hr />

            <Summary
              cart={cart}
              freeze={freeze}
              timeSlotId={timeSlotId}
              billingAddress={billingAddress}
              enableProductsReplacement={enableProductsReplacement}
              enableReplacement={enableReplacement}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              gift={gift}
              setGift={setGift}
            />

          </div>
        }
      </Translation>
    );
  }
}
