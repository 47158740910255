/* eslint-disable */

import * as actionTypes from "../actions/actionTypes";
import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    showOverlay: false,
}

const userReducer = createReducer(initialState, {
    [actionTypes.OVERLAY]: (state, action) => { state.showOverlay = action.showOverlay },
})

export default userReducer;

