import * as actionTypes from "./actionTypes"
import toaster from "../../../utils/toaster"
import { ecommerceCartsService } from "../../../services/ecommerce/EcommerceCartsService"
import { ecommerceService } from "../../../services/ecommerce/EcommerceService"
import { history } from "../../../App"
import config from "../../../config/config"
import i18n from "../../../i18n"
import swal from "../../../utils/swal"

export const cartAction = (cart) => {
    return {
        type: actionTypes.CART,
        cart: cart
    }
}

export const availabilityAction = (data) => {
    return {
        type: actionTypes.AVAILABILITY,
        availability: data
    }
}

export const saveCheckoutInfoAction = (data) => {
    return {
        type: actionTypes.CHECKOUT_DAY_INFO,
        data
    }
}

export const saveCheckoutInfo = (data) => {
    return (dispatch) => {
        console.log('saveCheckoutInfo', data)
        dispatch(saveCheckoutInfoAction(data))
    }
}

export const current = () => {
    return (dispatch) => {
        ecommerceCartsService.current()
            .then(response => {
                dispatch(cartAction(response.data))
                ecommerceService.availability()
                    .then(response => {
                        dispatch(availabilityAction(response.data))
                    }).catch((error) => {
                        console.log(error);
                    })
            }).catch((error) => {
                console.log(error);
                dispatch(cartAction(null))
            })
    }
};

export const availability = () => {
    return dispatch => {
        ecommerceService.availability()
            .then(response => {
                dispatch(availabilityAction(response.data))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const open = (deliveryMode, storeId, addressId, afterOpenCart) => {
    return (dispatch, getState) => {
        ecommerceCartsService.open(deliveryMode, storeId, addressId)
            .then(response => {
                dispatch(cartAction(response.data.cart))

                // add unauthenticathed product
                if (getState().cart.productToAdd) {
                    const item = getState().cart.productToAdd;
                    ecommerceCartsService.store(response.data.cart.id, item.barcode, item.amount, item.um)
                        .then((response) => {
                            const um = response.data.updatedProduct.um;
                            dispatch(addProductToAddAction(response.data.cart))
                            toaster.standard(`${i18n.t("cart.addedProduct.youHave")} ${item.amount} ${um} ${response.data.updatedProduct.description} ${i18n.t("cart.addedProduct.inCart")}`)
                        }).catch((error) => {
                            console.log(error);
                            toaster.error(error.message);
                        })
                }

                // get first availability
                ecommerceService.availability()
                    .then(response => {
                        dispatch(availabilityAction(response.data))
                    }).catch((error) => {
                        console.log(error);
                    })

            }).catch((error) => {
                // if there is and error i'll make available the button
                afterOpenCart();
                toaster.error(error.message);
            })
    }
};

export const store = (cartId, barcode, amount, um) => {
    return dispatch => {
        ecommerceCartsService.store(cartId, barcode, amount, um)
            .then((response) => {
                const um = response.data.updatedProduct.um;
                dispatch(cartAction(response.data.cart))
                toaster.standard(`${i18n.t("cart.addedProduct.youHave")} ${amount} ${um} ${response.data.updatedProduct.description} ${i18n.t("cart.addedProduct.inCart")}`)
            }).catch((error) => {
                console.log(error);
                toaster.error(error.message);
            })
    }
};

export const remove = (cartId, barcode) => {
    return dispatch => {
        ecommerceCartsService.remove(cartId, barcode)
            .then((response) => {
                dispatch(cartAction(response.data.cart))
                toaster.standard(`${response.data.updatedProduct.description} rimosso dal carrello`)
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const freeze = (data, cartId, paymentMode) => {
    return dispatch => {
        ecommerceCartsService.freeze(data, cartId, paymentMode)
            .then(response => {
                console.log(response);
                if (response?.data?.cart?.paymentMode === "APCOPAY") {
                    ecommerceCartsService.redirectToApcopay(response?.data?.cart?.orderId)
                        .then(response => {
                            console.log(response);
                            window.location.href = response.data
                            // dispatch(cartAction(null))
                            ecommerceCartsService.current()
                                .then(response => {
                                    dispatch(cartAction(response.data))
                                    ecommerceService.availability()
                                        .then(response => {
                                            dispatch(availabilityAction(response.data))
                                        }).catch((error) => {
                                            console.log(error);
                                        })
                                }).catch((error) => {
                                    console.log(error);
                                })
                        })
                        .catch(error => console.log(error))
                } else {
                    history.push("/checkout/confirm");
                    dispatch(cartAction(null))
                    ecommerceCartsService.current()
                        .then(response => {
                            dispatch(cartAction(response.data))
                            ecommerceService.availability()
                                .then(response => {
                                    dispatch(availabilityAction(response.data))
                                }).catch((error) => {
                                    console.log(error);
                                })
                        }).catch((error) => {
                            console.log(error);
                        })
                }
            }).catch((error) => {
                toaster.error(error.message)
                console.log(error);
            })
    }
};


export const move = (cartId, storeId) => {
    return dispatch => {
        ecommerceCartsService.move(cartId, storeId)
            .then(response => {
                history.push("/cart");
                dispatch(cartAction(response.data.cart))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const abort = (cartId) => {
    return dispatch => {
        ecommerceCartsService.abort(cartId)
            .then((response) => {
                dispatch(cartAction(null))
                dispatch(availabilityAction(null))


                ecommerceCartsService.current()
                    .then(response => {
                        dispatch(cartAction(response.data))

                        ecommerceService.availability()
                            .then(response => {
                                dispatch(availabilityAction(response.data))
                            }).catch((error) => {
                                console.log(error);
                            })
                    }).catch((error) => {
                        console.log(error);
                    })

            }).catch((error) => {
                console.log(error);
            })
    }
};

export const review = (cartId) => {
    return dispatch => {
        ecommerceCartsService.review(cartId)
            .then(response => {
                console.log(response);
                dispatch(cartAction(response.data.cart))

            }).catch((error) => {
                toaster.error(i18n.t("error.cartNotValid"))
            })
    }
};

export const setProductToAddAction = (product) => {
    return {
        type: actionTypes.SET_PRODUCT_TO_ADD,
        productToAdd: product
    }
}

export const setProductToAdd = (product, amount) => {
    return dispatch => {
        product.amount = amount;
        dispatch(setProductToAddAction(product))
    }
};

export const addProductToAddAction = (cart) => {
    return {
        type: actionTypes.ADD_PRODUCT_TO_ADD,
        cart: cart,
        productToAdd: null
    }
}

export const copy = (orderId) => {
    return dispatch => {
        ecommerceCartsService.copyOrder(orderId)
            .then(response => {
                console.log(response);
                if (response && response.responseCode === 4105) {
                    dispatch(freezeCheckAction(response.data, true))
                    history.push("/cart");

                } else if (response && response.responseCode === 0) {
                    dispatch(cartAction(response.data.cart))
                    history.push("/cart");
                }
            })
    }
};

export const freezeCheck = (data, showPopup) => {
    return dispatch => {
        dispatch(freezeCheckAction(data, showPopup))
    }
};

export const freezeCheckAction = (data, showPopup) => {
    return {
        type: actionTypes.FREEZE_CHECK,
        cart: data.cart,
        updatedItems: data.updatedItems,
        removedItems: data.removedItems,
        showFreezePopup: showPopup
    }
}

export const closeFreezePopup = () => {
    return dispatch => {
        dispatch(closeFreezePopupAction())
    }
};

export const closeFreezePopupAction = () => {
    return {
        type: actionTypes.CLOSE_FREEZE_POPUP,
        updatedItems: null,
        removedItems: null,
        showFreezePopup: false
    }
}

export const getCarts = () => {
    return dispatch => {
        ecommerceCartsService.openCarts()
            .then(response => {
                console.log(response);
                dispatch(getCartsAction(response.data));
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const getCartsAction = (carts) => {
    return {
        type: actionTypes.CARTS,
        carts: carts
    }
}

export const slistToCart = (slistId) => {
    return dispatch => {
        ecommerceCartsService.slistToCart(slistId)
            .then(response => {
                history.push("/cart");
                dispatch(cartAction(response.data))
                swal.success(i18n.t("smartlist.slistToCart.success"));
            }).catch((error) => {
                console.log(error);
                if (error.errorCode === 4004) {
                    history.push("/cart");
                }
            })
    }
};
