import CustomError from "./customError";
import store from "../redux/store";
import { logoutAction } from "../redux/store/actions/user"
import { history } from "../App";

export function handleResponse(response) {
    if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            localStorage.removeItem("user")
            history.push("/")
            store.reduxStore.dispatch(logoutAction());
            throw new CustomError(response.status, "Communication error with the server");
        }

        if ([400].indexOf(response.status) !== -1) {
            return Promise.reject("Communication error with the server");
        }
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.data;
    } else {
        return response;
    }
}

