import React, { useState, useEffect } from "react"
import ValidateInput from "../../../components/UI/Input/ValidateInput";
import { Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"
import { addressesService } from "../../../services/basis/AddressesService";

import "./profile.styles.scss"

function Profile(props) {
    const { t } = useTranslation();
    const [prefixes, setPrefixes] = useState([]);
    const [mobilePhonePrefix, setMobilePhonePrefix] = useState(props.user ? props.user.mobilePhonePrefix : "");
    const [homePhonePrefix, setHomePhonePrefix] = useState(props.user ? props.user.homePhonePrefix : "");

    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onBlur',
    });

    useEffect(() => {
        addressesService.prefixes()
            .then(response => {
                setPrefixes(response.data);
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        register(
            { name: "mobilePhonePrefix" },
            { required: t("required.field") },
        )
        register(
            { name: "homePhonePrefix" },
        )
        setValue("mobilePhonePrefix", mobilePhonePrefix);
        setMobilePhonePrefix(mobilePhonePrefix)
        setValue("homePhonePrefix", homePhonePrefix);
        setHomePhonePrefix(homePhonePrefix)
    }, [t, register, mobilePhonePrefix, homePhonePrefix])

    const selectMobilePhonePrefix = prefix => {
        setValue("mobilePhonePrefix", prefix);
        setMobilePhonePrefix(prefix)

        setValue("mobilePhone", "");
    }

    const selectHomePhonePrefix = prefix => {
        setValue("homePhonePrefix", prefix);
        setHomePhonePrefix(prefix)

        setValue("homePhone", "");
    }

    const onSubmit = data => {
        props.updateUser(data);
    }

    // console.log(watch())
    // console.log(watch('errors'))

    return (

        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" className={`needs-validation ${errors ? "had-validation" : ""}`}>
            <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
            <div className="profile">
                <p className="profile-title">{t('signup.title')}</p>
                <div>
                    <Row className="signup-row">
                        <Col md={6} className="form-group">
                            <ValidateInput
                                name="firstName"
                                label="firstName"
                                type="text"
                                pattern="[A-Za-z\s]"
                                value={props.user && props.user.firstName}
                                watch={watch("firstName")}
                                register={register({
                                    required: t("required.field"),
                                })}
                            />
                            <div className="form-control-invalid">
                                {errors.firstName && errors.firstName.message}
                            </div>
                        </Col>
                        <Col md={6} className="form-group">
                            <ValidateInput
                                name="lastName"
                                label="lastName"
                                type="text"
                                pattern="[A-Za-z\s]"
                                value={props.user && props.user.lastName}
                                watch={watch("lastName")}
                                register={register({
                                    required: t("required.field"),
                                })}
                            />
                            <div className="form-control-invalid">
                                {errors.lastName && errors.lastName.message}
                            </div>
                        </Col>
                    </Row>
                    <Row className="signup-row">
                        {/* <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="birthDate"
                                            label="birthDate"
                                            type="date"
                                            value={props.user && props.user.birthDate &&
                                                format(parseISO(props.user.birthDate), "yyyy-MM-dd")}
                                            watch={watch("birthDate")}
                                            register={register({
                                                required: t("required.field"),
                                                pattern: {
                                                    value: /^\d{4}-\d{2}-\d{2}$/i,
                                                    message: "Data non valida"
                                                }
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.birthDate && errors.birthDate.message}
                                        </div>
                                    </Col> */}

                        <Col md={2} className="form-group">
                            <select className="signup-select"
                                name="mobilePhonePrefix"
                                value={mobilePhonePrefix}
                                onChange={(e) => selectMobilePhonePrefix(e.target.value)}>
                                <option disabled selected value="">Prefix</option>
                                {prefixes && prefixes.map(item => (
                                    <option key={item.id} value={item.phonePrefix}>
                                        + {item?.phonePrefix}
                                    </option>
                                ))}
                            </select>
                            <div className="form-control-invalid">
                                {errors.mobilePhonePrefix && errors.mobilePhonePrefix.message}
                            </div>
                        </Col>
                        <Col md={4} className="form-group">
                            <ValidateInput
                                name="mobilePhone"
                                label="mobilePhone"
                                type="tel"
                                maxLength={mobilePhonePrefix === "356" ? 8 : 11}
                                pattern="[0-9]"
                                value={props.user && props.user.mobilePhone}
                                watch={watch("mobilePhone")}
                                disabled={!mobilePhonePrefix}
                                register={register({
                                    required: t("required.field"),
                                    pattern: {
                                        value: mobilePhonePrefix === "356" ? /[79][0-9]{7}/ : "",
                                        message: t("error.invalidPhone")
                                    }
                                })}
                            />
                            <div className="form-control-invalid">
                                {errors.mobilePhone && errors.mobilePhone.message}
                            </div>
                        </Col>
                        <Col md={2} className="form-group">
                            <select className="signup-select"
                                name="homePhonePrefix"
                                value={homePhonePrefix}
                                onChange={(e) => selectHomePhonePrefix(e.target.value)}>
                                <option disabled selected value="">Prefix</option>
                                {prefixes && prefixes.map(item => (
                                    <option key={item.id} value={item.phonePrefix}>
                                        + {item?.phonePrefix}
                                    </option>
                                ))}
                            </select>
                            <div className="form-control-invalid">
                                {errors.homePhonePrefix && errors.homePhonePrefix.message}
                            </div>
                        </Col>
                        <Col md={4} className="form-group">
                            <ValidateInput
                                name="homePhone"
                                label="HomePhone"
                                maxLength={homePhonePrefix === "356" ? 8 : 11}
                                type="tel"
                                value={props.user && props.user.homePhone}
                                watch={watch("homePhone")}
                                pattern="[0-9]"
                                disabled={!homePhonePrefix}
                                register={register({
                                    pattern: {
                                        value: homePhonePrefix === "356" ? /2[0-9]{7}/ : "",
                                        message: t("error.invalidPhone")
                                    }
                                })}
                            />
                            <div className="form-control-invalid">
                                {errors.homePhone && errors.homePhone.message}
                            </div>
                        </Col>
                    </Row>
                </div>


                {props.user && props.user?.cards && props.user?.cards[0] && props.user?.cards[0]?.code &&
                    <>
                        <p className="profile-subtitle">{t('signup.card')}</p>
                        <div>
                            <Row className="signup-row">
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="profileCard"
                                        label="profileCard"
                                        type="text"
                                        pattern="[0-9]"
                                        value={props.user && props.user.cards && props.user.cards[0] && props.user.cards[0].code}
                                        watch={watch("profileCard")}
                                        disabled
                                        register={register({
                                            pattern: {
                                                value: /^0[0-9]{12}$/,
                                                message: t("error.invalidCard")
                                            }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.profileCard && errors.profileCard.message}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </>
                }

                <div style={{ marginTop: "40px", marginBottom: "20px" }} className="profile-subtitle">{t('signup.privacy')}</div>

                {/* <textarea
                            rows="10"
                            cols="69"
                            // defaultValue={privacy}
                            defaultValue="Privacy policy"
                            readOnly
                        /> */}

                <div className="custom-control custom-checkbox">
                    <input className="custom-control-input" type="checkbox" id="accept1" name="accept1"
                        defaultChecked={props.user && props.user.accept1}
                        disabled={props.user && props.user.accept1}
                        ref={register({
                            required: t("required.field"),
                        })}
                    />
                    <label className="custom-control-label" htmlFor="accept1">
                        {t("profile.acceptTermsAndConditions")}
                    </label>
                </div>
                <div className="custom-control custom-checkbox">
                    <input className="custom-control-input" type="checkbox" id="accept2" name="accept2"
                        defaultChecked={props.user && props.user.accept2}
                        disabled={props.user && props.user.accept2}
                        ref={register({
                            required: t("required.field"),
                        })}
                    />
                    <label className="custom-control-label" htmlFor="accept2">
                        {t("profile.acceptPrivacyPolicy")}
                    </label>
                </div>

                <div className="profile-save">
                    <button className="cta" type="submit">
                        <span>{t("save")}</span>
                    </button>
                </div>
            </div>
        </form>
    );
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateUser: (data) => dispatch(actionCreators.updateUser(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);