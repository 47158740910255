import React from 'react'

const Loyalty = () => {
    return (
        <div style={{ padding: "10px" }}>
            <h2>Loyalty Card Terms and Conditions</h2><br />
            Apply for a loyalty card<br /><br />
            To apply for a PAVI or PAMA Supermarket loyalty card, visit our customer care desks at PAVI-PAMA supermarkets or register online at www.pavipama.com.mt<br /><br />
            Customers holding a PAVI or PAMA Supermarket loyalty card benefit from exclusive discounts which are only available to loyalty card holders.<br /><br />
            Earn points<br /><br />
            To earn points, present loyalty card to the cashier before cashing in items.<br /><br />
            For every €1 spent at PAVI or PAMA Supermarkets, the loyalty card holder gets one (1) loyalty point.<br /><br />
            With every six hundred and eighty (680) points, the loyalty card holder is entitled to a €5 cash voucher.<br /><br />
            Conditions of use<br /><br />
            The PAVI loyalty card is only valid at PAVI Supermarket.<br /><br />
            The PAMA loyalty card is only valid at PAMA Supermarket.<br /><br />
            Customers must be over 18 years of age to apply for a loyalty card.<br /><br />
            Points are only redeemable by person whose name appears on loyalty card upon presentation of ID card.<br /><br />
            The loyalty card remains the property of PAVI-PAMA Supermarkets, who reserve the right to decline or withdraw it at any time.<br /><br />
            The information applicants submit via the Loyalty Card Application form constitutes personal data as defined by the EU's General Data Protection Regulation.<br /><br />
            PAVI-PAMA Supermarkets will collect data for the purposes of rendering the Loyalty Card service which one signs up to through the registration form, as well as keeping loyalty card holders informed about our products and services.<br /><br />
            PAVI-PAMA will only process the data in the manner explained in our Privacy Notices which are accessible at www.pavipama.com.mt.<br /><br />
            Loyalty card holders may opt out from our mailing list at any time by visiting  www.pavipama.com.mt and adjusting their marketing preferences.<br /><br />
        </div>
    )
}

export default Loyalty