import React from "react"
import Placeholder from "../../../images/product-placeholder.png"
import { ReactComponent as Cart } from '../../../images/Cart.svg';
import { ReactComponent as SlistIcon } from "../../../images/slist.svg"
import { ReactComponent as CheckList } from "../../../images/check-list.svg"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom";
import config from "../../../config/config";
import { Translation } from "react-i18next";
import * as actionCreators from "../../../redux/store/actions/index"
import renderPrice from "../../../utils/renderPrice";
import NewCounter from "../../UI/NewCounter/NewCounter";
import SlistPopup from "../../../features/Smartlist/SlistPopup/SlistPopup";
// import { history } from "../../../App";

import "./card-product.styles.scss";
import { slists } from "../../../redux/store/actions/slists";
import toaster from "../../../utils/toaster";
class CardProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: null,
            isAlreadyInList: false,
            showPopup: false
        }
    }

    componentDidMount() {
        this.setCartAmount();
        this.setIsInList();
    }

    componentDidUpdate(prevProps, prevState) {
        const { cart, currentSlist } = this.props;
        if (prevProps?.cart?.id !== cart?.id || prevProps.cart?.items?.length !== cart?.items?.length) {
            this.setCartAmount();
        }
        if (prevProps.currentSlist?.items?.length !== currentSlist?.items?.length) {
            this.setIsInList();
        }
    }

    setCartAmount = () => {
        const { cart, item } = this.props;
        var isAlreadyBooked = false;
        var itemAlreadyBooked = null;
        if (cart && cart.items) {
            cart.items.forEach(element => {
                if (element.ref === item.ref) {
                    isAlreadyBooked = true;
                    itemAlreadyBooked = element;
                }
            });
        }
        if (isAlreadyBooked) {
            if (itemAlreadyBooked.purchaseUm === "PZ") {
                this.setState({ amount: itemAlreadyBooked.amount })
            } else {
                this.setState({ amount: itemAlreadyBooked.weight })
            }
        } else {
            this.setState({ amount: item?.minWeight ? item.minWeight : item?.umStep })
        }
    }

    setIsInList = () => {
        const { currentSlist, item } = this.props;
        var isAlreadyInList = false;
        if (currentSlist && currentSlist.items) {
            currentSlist.items.forEach(element => {
                if (element.ref === item.ref) {
                    isAlreadyInList = true;
                }
            });
        }
        this.setState({ isAlreadyInList: isAlreadyInList })
    }

    incrementAmount = () => {
        const { item } = this.props;
        const { amount } = this.state;
        let newAmount = +amount + item.umStep;
        newAmount = newAmount.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (item?.maxAmount) {
            if (newAmount <= item.maxAmount) {
                this.setState({ amount: newAmount })
                if (newAmount === item.maxAmount) toaster.standard("Maximum amount reached")
            }
        } else {
            this.setState({ amount: newAmount })
        }
    }

    decrementAmount = () => {
        const { item } = this.props;
        const { amount } = this.state;
        let newAmount = +amount - item?.umStep;
        newAmount = newAmount.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (item.minWeight) {
            if (amount > item.minWeight) {
                this.setState({ amount: newAmount })
            }
        } else if (amount > item?.umStep) {
            this.setState({ amount: newAmount })
        }
    }

    changeAmount = (event) => {
        this.setState({ amount: event.target.value })
    }

    renderPrices = (item) => {
        if (item.price !== item.netPrice) {
            return (
                <nobr>
                    <span className="text-strike strike">
                        {`${renderPrice(item.price.toFixed(2))} \u20AC`}
                    </span>
                </nobr>
            );
        } else {
            return <span></span>
        }
    }

    renderTotal = (item) => {
        if (item.rowNetPriceWithCartItemRelated) {
            return <nobr><span className="netTotal">{`${renderPrice(item.rowNetPriceWithCartItemRelated.toFixed(2))} \u20AC`}</span></nobr>
        } else {
            return <nobr><span className="netTotal">{`${renderPrice(item.netPrice.toFixed(2))} \u20AC`}</span></nobr>
        }
    }

    renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                // const label = item.promotions[0].valueType === "UNKNOWN" ? "promo.unknown" : "promo";
                return (
                    <Translation>
                        {t =>
                            <div className="card-product-promo">
                                <span>{t("cardProduct.offer")}</span>
                            </div>
                        }
                    </Translation>
                );
            } else {
                return null;
            }
        }
    }

    closeSlistPopup = () => this.setState({ showPopup: false })

    render() {
        const { item, user, cart, setProductToAdd, showLogin, slists, itemCreate } = this.props;
        const { isAlreadyInList, showPopup, amount } = this.state;
        return (
            <Translation>
                {t =>
                    <>
                        <div className="card-product" style={{ ...this.props.style }}>
                            <div className={"card-product-container"}>
                                <div className={"card-product-image"}>
                                    <div
                                    // className="link" 
                                    // onClick={() => this.goToDetails(item)}
                                    >
                                        <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                                            onError={(e) => {
                                                e.target.src = Placeholder
                                            }}
                                        />
                                    </div>
                                </div>
                                {item.available &&
                                    this.renderPromo(item)
                                }
                                {!item.available &&
                                    <div className="out-of-stock">Out of stock</div>
                                }
                            </div>
                            <div className="card-product-details">
                                <div
                                // className="link" 
                                // onClick={() => this.goToDetails(item)}
                                >
                                    <span>
                                        {item.description}
                                        {item.cartItemRelatedProduct ?
                                            ` +  ${item.cartItemRelatedProduct?.description}` : ""
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="card-product-um">
                                {item.pricePerUm && item.umPerUm &&
                                    `${renderPrice(item.pricePerUm.toFixed(2))} \u20AC / ${t(item.umPerUm)} `
                                }
                                {item.cartItemRelatedProduct &&
                                    ` + ${renderPrice(item.cartItemRelatedProduct?.price.toFixed(2))} \u20AC / pcs`
                                }
                            </div>
                            <div className="card-product-prices">
                                {this.renderPrices(item)}
                                {/* <span className="discount">{`-2.00 \u20AC`}</span> */}
                                <nobr><span className="netTotal">{`${renderPrice(item.rowNetPriceWithCartItemRelated ? item.rowNetPriceWithCartItemRelated?.toFixed(2) :
                                    item.netPrice.toFixed(2))} \u20AC`}</span></nobr>
                            </div>

                            <div className="card-product-buttons">
                                <NewCounter
                                    value={this.state.amount}
                                    um={item.purchaseUm}
                                    increment={this.incrementAmount}
                                    decrement={this.decrementAmount}
                                    writable={false}
                                    changeAmount={this.changeAmount}
                                    pattern="^\d(\.\d)*$"
                                    disabled={!item.available}
                                    disablePlus={item?.maxAmount === amount}
                                />
                                <button className="cart outline"
                                    disabled={!item.available}
                                    onClick={() => {
                                        if (user && cart) {
                                            // this.isAlreadyBooked(item)
                                            this.props.store(this.props?.cart?.id, item.barcode, this.state.amount, item.purchaseUm);
                                        }
                                        if (user && !cart) {
                                            setProductToAdd(item, this.state.amount);
                                            this.props.history.push("/cart")
                                        }
                                        if (!user) {
                                            setProductToAdd(item, this.state.amount);
                                            showLogin();
                                        }
                                    }}>
                                    <Cart />
                                    <span className="d-none d-md-block">{t("cardProduct.buy")}</span>
                                </button>
                            </div>
                            {config.ENABLED_SLIST &&
                                <div className="card-product-slist">
                                    {isAlreadyInList ?
                                        <button className="is-already-in-list outline">
                                            <CheckList />
                                            {t("cardProduct.alreadyInList")}
                                        </button>
                                        :
                                        <button className="add-to-list outline"
                                            disabled={!item.available}
                                            onClick={() => {
                                                if (user && slists.length > 1) {
                                                    // currentSlist && this.props.itemCreate(currentSlist.id, item.barcode, 1)
                                                    this.setState({ showPopup: true })
                                                }
                                                else if (user && slists.length == 1) {
                                                    itemCreate(slists[0].id, item.barcode, amount)
                                                }
                                                else {
                                                    showLogin();
                                                }
                                            }}>
                                            <SlistIcon />
                                            {t("cardProduct.addToList")}
                                        </button>
                                    }
                                </div>
                            }
                        </div>
                        {showPopup &&
                            <SlistPopup
                                show={showPopup}
                                barcode={item.barcode}
                                amount={amount}
                                onClose={this.closeSlistPopup}
                            />
                        }
                    </>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        currentSlist: state.slists.currentSlist,
        slists: state.slists.slists,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setProductToAdd: (item, amount) => dispatch(actionCreators.setProductToAdd(item, amount)),
        store: (cartId, barcode, amount, um) => dispatch(actionCreators.store(cartId, barcode, amount, um)),
        itemCreate: (listId, barcode, amount) => dispatch(actionCreators.itemCreate(listId, barcode, amount)),
        showLogin: () => dispatch(actionCreators.showLogin(true)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardProduct));