import React, { useEffect, useState } from 'react'
import { couponsService } from "./Service"
import { useTranslation } from 'react-i18next';
import Placeholder from "../../images/coupons-placeholder.jpg"
import { ReactComponent as Activated } from "../../images/coupon-activated.svg"

import "./coupons.styles.scss";

const Coupons = () => {

    const [coupons, setCoupons] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        couponsService.getCoupons()
            .then(response => {
                console.log(response.data)
                setCoupons(response.data)
            }).catch(error => {
                console.log(error);
            });
    }, [])

    const activeCoupon = (couponId) => {
        couponsService.activeCoupon(couponId)
            .then(response => {
                console.log(response)
                setCoupons(response.data)
            }).catch(error => {
                console.log(error);
            });;
    }

    return (
        <div className="coupons">
            <div className="coupons-title">{t("coupons.title")}</div>
            <div className="coupons-content">
                {coupons && coupons.map(item => (
                    <div key={item.id} className="coupons-card">
                        <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                            onError={(e) => e.target.src = Placeholder}
                        />
                        <div className="description">{item.description}</div>
                        <button className={`cta coupon-${item.status}`}
                            onClick={() => activeCoupon(item.id)}>
                            {item.status === "ACTIVATED" &&
                                <Activated />
                            }
                            <span>{t(`coupon.${item.status}`)}</span>
                        </button>
                    </div>
                ))}
                {(!coupons || coupons.length <= 0) &&
                    <span className="coupons-noItems">
                        {t("coupons.noItems")}
                    </span>
                }
            </div>
        </div>
    );
}

export default Coupons;

