import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import config from '../../config/config';
import axios from 'axios';


export const addressesService = {
    states, counties, cities, zipcodes, prefixes
};

function states() {
    return (
        axios.get(`${config.baseUrl}/cli/addresses/states`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function counties(stateId, query) {
    return (
        axios.get(`${config.baseUrl}/cli/addresses/counties?stateId=${stateId}&q=${query}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function cities() {
    return (
        axios.get(`${config.baseUrl}/cli/addresses/cities`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function zipcodes(cityId, query) {
    return (
        axios.get(`${config.baseUrl}/cli/addresses/zipcodes?cityId=${cityId}&q=${query}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function prefixes() {
    return (
        axios.get(`${config.baseUrl}/cli/addresses/nations`)
            .then(handleResponse)
            .then(checkResponse)
    );
}