import config from "../../../config/config"
import { handleResponse } from "../../../utils/handleResponse";
import { checkResponse } from "../../../utils/checkResponse";
import { authHeader } from "../../../utils/authHeader";
import axios from 'axios';

export default function changePassword(oldPassword, newPassword) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/password/change`, 
        {
            oldPassword : oldPassword,
            newPassword: newPassword
        },
        {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}