import React from "react";
import CookieConsent from "react-cookie-consent";
import FacebookIcon from "../../../images/facebook-square-brands.svg";
import PlayStore from "../../../images/PlayStore.png";
import AppStore from "../../../images/AppStore.png";
import InstagramIcon from "../../../images/instagram-square-brands.svg";
// import { ReactComponent as Logo } from "../../../images/logo-footer.svg";
import Logo from "../../../images/logo_smartbip_bianco.png";
import { Translation } from "react-i18next";

import "./footer.styles.scss";
import { Link } from "react-router-dom";

export default class Footer extends React.Component {
    render() {
        const baseUrl = window.location.origin;
        return (
            !location.pathname.includes("/app/share") &&
            <Translation>
                {t =>
                    <div className="footer">
                        {/* <CookieConsent
                            location="bottom"
                            buttonText="OK"
                            style={{ background: "#2B373B" }}
                            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                            expires={150}>
                            {t("footer.cookieConsent")}
                        </CookieConsent> */}
                        <div className="footer-content">
                            <div className="row container-fluid">
                                {/* <Logo /> 
                            <img src={Logo} alt="smartbip" />
                            */}
                                <div className="col-lg-2 offset-lg-1 col-md-6 col-12 text-center text-md-left mb-3 mb-lg-0 mobile-col">
                                    <div className="footer-text">
                                        <span className="bold" color='#d3d81a'>
                                            {t("footer.pavi.pama")}
                                        </span>
                                    </div>
                                    <div className="footer-text">
                                        <Link className="footer-text" to="/termsandconditions">
                                            {t("footer.general.terms.and.conditions")}
                                        </Link>
                                    </div>
                                    <div className="footer-text">
                                        <a href={`${baseUrl}/resources/TCs-WinBigThisSummer2024.pdf`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: "#302E49" }}>
                                            {t("footer.winBigTS")}
                                        </a>
                                    </div>
                                    <div className="footer-text">
                                        <a href={`${baseUrl}/resources/Gift+voucher+2020+-+new+version.pdf`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: "#302E49" }}>
                                            {t("footer.vouchers.terms.and.conditions")}
                                        </a>
                                    </div>
                                    {/* <div className="footer-text">
                                        <a href={`${baseUrl}/resources/WinBigthisChristmas.pdf`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: "#302E49" }}>
                                            {t("footer.christmas")}
                                        </a>
                                    </div> */}
                                    {/* <div className="footer-text">
                                        <a href={`${baseUrl}/resources/TCs-Promotion20210608v1.pdf`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: "#302E49" }}>
                                            {t("footer.orange.travel.crouise.promotions")}
                                        </a>
                                    </div> */}
                                    <div className="footer-text">
                                        <Link className="footer-text" to="/privacy">
                                            PAVI-PAMA privacy policy
                                        </Link>
                                    </div>
                                    <div className="footer-text">
                                        <Link className="footer-text" to="/contactUs">
                                            {t("footer.contactUs")}
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 text-center text-md-left mb-3 mb-lg-0 mobile-col">
                                    <div className="footer-text">
                                        <span className="bold" color='#d3d81a'>
                                            {t("footer.loyalty.card")}
                                        </span>
                                    </div>
                                    {/* <div className="footer-text">
                                        <a href="https://pg-group-stage.smartbip.it/app/signup"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: "#302E49" }}>
                                            {t("footer.register")}
                                        </a>
                                    </div> */}
                                    <div className="footer-text">
                                        <Link className="footer-text" to="/loyalty">
                                            {t("footer.loyalty.card.terms.and.conditions")}
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 text-center text-md-left mobile-col ">
                                    <div className="footer-text">
                                        <span className="bold">
                                            {t("footer.deliveries")}
                                        </span>
                                        <div className="footer-text">
                                            We Deliver across Malta<br></br>
                                            <span className="bold" style={{textTransform: "unset"}}>Delivery times subject to available time slots</span>
                                        </div>
                                        <span className="bold">
                                            {t("footer.customerSupport")}
                                        </span>
                                        <div className="footer-text">
                                            Tel: <span className="bold">+356 2349 6253</span><br></br>
                                            Email: <span className="bold" style={{textTransform: "unset"}}><a href="mailto:customersupport@pavipama.com.mt" style={{color: '#302E49'}}>customersupport@pavipama.com.mt</a></span><br></br>
                                            <span className="bold" style={{textTransform: "unset"}}>Available only during office hours</span>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-12 text-center text-md-left mobile-col d-none d-lg-block">
                                    <div className="footer-text">
                                        <span className="bold">
                                            {t("footer.followUs")}
                                        </span>
                                        <div className="mt-2">
                                            <a href="https://www.facebook.com/pavicomplex"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="footer-social-icon"
                                            >
                                                <img src={FacebookIcon} alt="facebook" />
                                            </a>
                                            <a href="https://www.instagram.com/pavipama/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="footer-social-icon"
                                            >
                                                <img src={InstagramIcon} alt="facebook" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-2 col-md-6 col-12 text-center text-md-right">
                                    <div className="footer-text">
                                        <span className="bold">
                                            <nobr>
                                                {t("footer.downloadApp")}
                                            </nobr>
                                        </span>
                                    </div>
                                    <div className="footer-apps">
                                        <div>
                                            <a href=""
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <button className="cta">
                                                    <img src={AppStore} alt="" />
                                                    <span className="bold">
                                                        {t("footer.appStore")}</span>
                                                </button>
                                            </a>
                                        </div>
                                        <div>
                                            <a href=""
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <button className="cta">
                                                    <img src={PlayStore} alt="" />
                                                    <span className="bold">
                                                        {t("footer.playStore")}</span>
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="footer-follow d-lg-none">
                                <div className="footer-text">
                                    <span className="bold">{t("footer.followUs")}</span>
                                </div>
                                <div>
                                    <a href=""
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-social-icon"
                                    >
                                        <img src={FacebookIcon} alt="facebook" />
                                    </a>
                                    <a href=""
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-social-icon"
                                    >
                                        <img src={InstagramIcon} alt="facebook" />
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="footer-bottom row no-gutters">
                            <div className="col-12 col-sm-8 text-center text-sm-left" >
                                <span>
                                    {t("footer.copyRight")}
                                </span>
                            </div>
                            <div className="col-12 col-sm-4 powered text-center text-sm-right">
                                <span>
                                    {t("footer.poweredBy")} <span className="bold">SmartBip</span>
                                </span>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}