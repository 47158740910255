import React from "react";
import { Translation } from 'react-i18next';
import { withRouter } from "react-router-dom"
import { ecommerceService } from "../../../services/ecommerce/EcommerceService";
// import { dayNumber } from "../../../utils/dateUtils";
import renderPrice from "../../../utils/renderPrice";
import moment from "moment";
import config from "../../../config/config";

import "./confirm.styles.scss"
import { ecommerceCartsService } from "../../../services/ecommerce/EcommerceCartsService";

class Confirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: null
        }
    }

    componentDidMount() {
        ecommerceService.orders(0)
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        order: response.data[0],
                    })
                }
            }).catch((error) => {
                console.log(error);
            })
        // setTimeout(() => {
        //     this.props.history.push("");
        // }, 3000);
    }

    render() {
        const { order } = this.state;
        console.log(order);
        return (
            <Translation>
                {t =>
                    <div className="checkout-confirm">
                        <div className="checkout-confirm-title text-center">
                            {order?.status === "PAYMENT_PENDING" ? t(`freeze.payment-pending`) : t('freeze.success')}
                        </div>
                        {order && order.cart && order.cart.payment && order.cart.payment.mode === "STRIPE" &&
                            <div className="checkout-confirm-subtitle">
                                <div>
                                    {t("confirm.subtitle")}
                                </div>
                                <div style={{ marginBottom: "20px" }}>
                                    {t("confirm.subtitleBottom")}
                                </div>
                            </div>
                        }
                        <div className="checkout-confirm-resume">
                            {order?.status === "PAYMENT_PENDING" &&
                                <div className="text-right">
                                    <span className="order-copy-button order-pay-button"
                                        onClick={() => {
                                            ecommerceCartsService.redirectToApcopay(order.id)
                                                .then(response => {
                                                    window.location.href = response.data
                                                })
                                        }}>
                                        {t("order.payOrder")}
                                    </span>
                                </div>
                            }
                            <div className="checkout-confirm-resume-title">
                                {t("confirm.orderNumber")} <span>{order && order.code}</span>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-4 label">{t("confirm.totalProducts")}</div>
                                <div className="col-12 col-md-8 text">
                                    {order && order.cart && order.cart.itemsCount}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-4 label">{t("confirm.import")}</div>
                                <div className="col-12 col-md-8 text">
                                    {order && order.cart && `${renderPrice(order.cart.totalToPay.toFixed(2))} \u20AC `}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-4 label">{t("confirm.chooseDeliveryOptions")}</div>
                                <div className="col-12 col-md-8 text">
                                    {order && order.cart && t(`cart.deliveryMode.${order.cart.deliveryMode}`)}
                                </div>
                            </div>
                            {/* {order && order.cart && order.cart.delivery && order.cart.delivery.timeSlot &&
                                <div className="row">
                                    <div className="col-12 col-md-4 label">{t("confirm.dateAndTimeOfDelivery")}</div>
                                    <div className="col-12 col-md-8 text">
                                        <>
                                            <span>{dayNumber(order.cart.delivery.timeSlot.weekDay)}</span>
                                            <span> ({order.cart.delivery.timeSlot.validFrom} - {order.cart.delivery.timeSlot.validTo})</span>
                                        </>
                                    </div>
                                </div>
                            } */}
                            {order && order.cart && order.cart.delivery && order.cart.delivery.deliveryDate &&
                                <div className="row">
                                    <div className="col-12 col-md-4 label">{t("confirm.dateOfDelivery")}</div>
                                    <div className="col-12 col-md-8 text">
                                        <span>{moment(order.cart.delivery.deliveryDate).format("DD/MM/YYYY")}</span>
                                    </div>
                                </div>
                            }
                            {order && order.cart && order.cart.delivery && order.cart.delivery.deliveryDate &&
                                <div className="row">
                                    <div className="col-12 col-md-4 label">{t("confirm.timeOfDelivery")}</div>
                                    <div className="col-12 col-md-8 text">
                                        <span> {order.cart.delivery.timeSlot.validFrom} - {order.cart.delivery.timeSlot.validTo}</span>
                                    </div>
                                </div>
                            }
                            {order && order.cart && order.cart.delivery &&
                                (order.cart.deliveryMode === "AT_HOME" || (order.cart.deliveryMode === "SHIPPED")) &&
                                <div className="row">
                                    <div className="col-12 col-md-4 label">{t("confirm.address")}</div>
                                    <div className="col-12 col-md-8 text">
                                        {order.cart.delivery.addressDescription}
                                    </div>
                                </div>
                            }
                            {order && order.cart && order.cart.delivery &&
                                (order.cart.deliveryMode === "IN_STORE" || (order.cart.deliveryMode === "DRIVE")) &&
                                <div className="row">
                                    <div className="col-12 col-md-4 label">{t("confirm.store")}</div>
                                    <div className="col-12 col-md-8 text">
                                        {order.cart.storeDescription}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="text-center">
                            <button style={{ width: "293px" }} className="cta"
                                onClick={() => {
                                    this.props.history.push("/");
                                }}>
                                <span>{t("confirm.returnBackHome")}</span>
                            </button>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

export default withRouter(Confirm);

