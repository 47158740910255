import React from 'react';
import { ReactComponent as EmailSuccessLogo } from '../../../images/Payment.svg';
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

function PaymentFailedPopup(props) {

    const { t } = useTranslation();

    const handleClose = () => props.closeAlert();

    return (
        <>
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header>
                </Modal.Header>
                <div className="modal-popup d-flex flex-column align-items-center">
                    <div className="email-success-log"><EmailSuccessLogo /></div>
                    <div className="modal-popup-heading">
                        {/* {t("signup.success.title")} */}
                        {props.alertMessage}
                    </div>
                    {/* <div>
                        <p className="modal-popup-text">
                            {t("signup.success.text")}
                        </p>
                    </div> */}
                    <div className="modal-popup-btn-div">
                        <button className="cta" onClick={handleClose}>
                            <span>{t("close")}</span>
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default PaymentFailedPopup;