import * as actionTypes from "../actions/actionTypes";

const initialState = {
    favorites: [],
    slists: [],
    currentSlist: null
}

const slistReducer = (state = initialState, action) => {
    var updatedFavorites, updatedSlists, updateCurrent;
    switch (action.type) {
        case actionTypes.FAVORITES:
            updatedFavorites = action.favorites;
            return {
                ...state,
                favorites: updatedFavorites
            };
        case actionTypes.ADD_FAVORITE:
            updatedFavorites = action.favorites;
            return {
                ...state,
                favorites: updatedFavorites
            };
        case actionTypes.DELETE_FAVORITE:
            updatedFavorites = action.favorites;
            return {
                ...state,
                favorites: updatedFavorites
            };
        case actionTypes.SLISTS:
            updatedSlists = action.slists;
            if (state.currentSlist) {
                return {
                    ...state,
                    slists: updatedSlists,
                    currentSlist: updatedSlists.length > 0 ? updatedSlists[0] : null
                };
            } else {
                return {
                    ...state,
                    slists: updatedSlists,
                    currentSlist: updatedSlists[0]
                };
            }
        case actionTypes.SLISTS_ITEM_CREATE:
            updateCurrent = action.currentSlist;
            return {
                ...state,
                currentSlist: updateCurrent
            };
        case actionTypes.SLISTS_ITEM_CREATE_CUSTOM:
            updateCurrent = action.currentSlist;
            return {
                ...state,
                currentSlist: updateCurrent
            };
        case actionTypes.SLISTS_ITEM_DELETE:
            updateCurrent = action.currentSlist;
            return {
                ...state,
                currentSlist: updateCurrent
            };
        case actionTypes.SLISTS_ITEM_UPDATE:
            updateCurrent = action.currentSlist;
            return {
                ...state,
                currentSlist: updateCurrent
            };
        case actionTypes.FETCH_SLIST:
            updateCurrent = action.currentSlist;
            return {
                ...state,
                currentSlist: updateCurrent
            };
        case actionTypes.CREATE_SLIST:
            updateCurrent = action.currentSlist;
            updatedSlists = action.slists;
            return {
                ...state,
                currentSlist: updateCurrent,
                slists: updatedSlists,
            };
        case actionTypes.DELETE_SLIST:
            updatedSlists = action.slists;
            return {
                ...state,
                slists: updatedSlists,
                currentSlist: updatedSlists[0]
            };
        case actionTypes.RENAME_SLIST:
            updateCurrent = action.currentSlist;
            updatedSlists = action.slists;
            return {
                ...state,
                currentSlist: updateCurrent,
                slists: updatedSlists,
            };
        case actionTypes.COPY_SLIST:
            updateCurrent = action.currentSlist;
            updatedSlists = action.slists;
            return {
                ...state,
                currentSlist: updateCurrent,
                slists: updatedSlists,
            };

        default: return state;
    }
};

export default slistReducer;

