import config from "../../config/config"
import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import { authHeader } from "../../utils/authHeader";
import axios from "axios";

export const ecommerceCartsService = {
    current, open, openCarts, store, review, remove, freeze, abort, move, copyOrder, freezeCheck, slistToCart, redirectToApcopay
}

function current() {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/cart/current`, {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}
function redirectToApcopay(orderId) {
    return (
        axios.get(`${config.baseUrl}/cli/pay/apcopay/authorize?orderId=${orderId}`, {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function open(deliveryMode, storeId, addressId) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/open`, 
        {
            storeId: storeId,
            deliveryMode: deliveryMode,
            addressId: addressId ? addressId : "",
            terminalType: "WEB",
            terminalId: "9095bfc3-2dad-44dc-89e0-b9f232542f32",
        },
        {
            headers: authHeader(),
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function openCarts() {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/carts/open`, {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function store(cartId, barcode, amount, um) {
    let body = null;
    if (um === "PZ") {
        body = {
            cartId: cartId,
            barcode: barcode,
            amount: amount,
            preview: false
        }
    } else {
        body = {
            cartId: cartId,
            barcode: barcode,
            weight: amount,
            preview: false
        }
    }
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/store`, 
        body,
        {
            headers: authHeader(),
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function remove(cartId, barcode) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/delete`, 
        {
            cartId: cartId,
            barcode: barcode,
            preview: false
        },
        {
            headers: authHeader(),
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function review(cartId) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/review`, 
        {
            cartId: cartId,
            refresh: false
        },
        {
            headers: authHeader(),
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function freeze(data, cartId, paymentMode) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/freeze`, 
        {
            cartId: cartId,
            profileEmail: data.profileEmail,
            profileMobilePhone: data.profileMobilePhone,
            profileMobilePhonePrefix: data.profileMobilePhonePrefix,
            firstName: data.profileFirstName,
            lastName: data.profileLastName,
            timeSlotId: data.timeSlotId,
            paymentMode: paymentMode,
            notes: data.notes,
            invoice: data.invoice,
            billingAddressId: data.billingAddressId,
            enableProductsReplacement: data.enableProductsReplacement,
            clientWeb: true,
            gift: data.gift,
            // timeSlotDay: data.day
        },
        {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function abort(cartId) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/abort`, 
        {
            cartId: cartId,
        },
        {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function move(cartId, storeId) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/move`, 
        {
            cartId: cartId,
            storeId: storeId,
        },
        {
            headers: authHeader(),
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function copyOrder(orderId) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/cart/copy-order/${orderId}`,
        {
            headers: authHeader()
        })
            .then(handleResponse)
        // .then(checkResponse)
    )
}

function freezeCheck(cartId, preview) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/freeze/check`, 
        {
            cartId: cartId,
            preview: preview
        },
        {
            headers: authHeader()
        })
            .then(handleResponse)
        // .then(checkResponse)
    )
}

function slistToCart(slistId) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/cart/copy-items/${slistId}`,
        {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}
