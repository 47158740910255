import React from 'react'

const Policy = () => {
    return (
        <div style={{ padding: "10px" }}>
            <h2>Pavi Privacy Policy</h2>
            Pavi is committed to protecting your personal data.<br /><br />
            We are aware of the discomfort brought about by having to read endless pages of complicated text. For this reason, our privacy policy – which can be accessed directly below – has been drafted in a manner that is simple and easy-to-read.
            This Privacy Notice was updated in May 2018. We may update it from time to time. If we believe that any changes will affect you, we will let you know before they are made.<br /><br />
            This privacy policy applies to the supermarket operations of Pavi (https://www.pavipama.com.mt/) (the “Website”). It is important that you read this privacy policy together with any other privacy or fair processing notice we may provide on specific occasions when we collect your personal data so that you are fully aware of the manner in which we use your personal data.<br /><br />

            1.	Who we are.<br /><br />

            Throughout this privacy policy, the terms ‘we’ and ‘our’ refer to Pavi Supermarkets Limited (“Pavi”), which forms part of the PG group of companies (https://pggroup.com.mt/) (“PG Group”). Pavi is the controller of your personal data. As explained further below, Pavi collects and handles your personal data when, for example, you sign up for the supermarket’s loyalty card schemes, create an account on our Website, and purchase our products.<br /><br />
            OUR CONTACT DETAILS.<br /><br />

            Full name of controller: 			Pavi Supermarkets Limited<br />

            Company registration number: 		C29878<br />

            Postal address: 				Pavi Supermarkets, Triq Manuel Dimech, Qormi, Malta<br />

            Email address: 				dataprotection@pggroup.com.mt<br />

            Telephone number: 			(+356) 2270 0000<br /><br />


            2. Data we collect about you.<br /><br />

            We may collect, use, store and transfer different kinds of personal data about you which we have grouped together follows:<br />

            Identity Data includes name, username or similar identifier, title, age and gender.<br />
            Contact Data includes home address, email address and telephone numbers.<br />
            Financial Data includes payment card details.<br />
            Transaction Data includes details about payments to and from you and other details of products you have purchased from us.<br />
            Technical Data includes IP address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this Website.<br />
            Profile Data includes your username and password, loyalty card details, purchases or orders made by you, your interests, feedback and survey responses.<br />
            Usage Data includes information about how you use our Website, products and services.<br />
            Marketing and Communications Data includes your preferences in receiving our promotional content.<br />
            Certain other information may be classified as special categories of personal data. Such include any information relating to the following;<br />
            Racial or ethnic origin;<br />
            Political opinions;<br />
            Religious or philosophical beliefs;<br />
            Trade union membership;<br />
            Genetic data;<br />
            Biometric data for the purpose of uniquely identifying a natural person;<br />
            Data concerning health;<br />
            Data concerning a natural person's sex life or sexual orientation.<br />
            Unless authorised or required by law, we will never store or process any data which is deemed to consist of special categories of data without obtaining your prior written consent.<br /><br />
            3. How do we collect your data?<br /><br />

            We collect your personal data through different methods, including:<br />

            Direct interactions: you may give us your Identity, Contact and Financial Data by filling in forms or by corresponding with us by phone, email or otherwise. This includes personal data you provide when you:<br />
            create an account on our Website;<br />
            purchase products through our Website;<br />
            subscribe to our loyalty card schemes;<br />
            send us your CV should you be interested in joining our team;<br />
            request marketing to be sent to you;<br />
            enter a competition, promotion or survey;<br />
            provide us with any feedback.<br />

            Automated technologies or interactions: when you access our Website, we may automatically collect Technical Data about your devices and browsing patterns. We collect this personal data by using cookies and other similar technologies.<br />
            Cookies are text files which are placed on your computer, tablet or mobile phone when using our Website, to collect standard internet log information and visitor information on our Website. Our Website creates cookies every time you visit it. Cookies are used for the following reasons:<br />
            To analyse the traffic on our Website;<br />
            To make improvements to our Website;<br />
            To ensure that visits to our Websites are properly recorded.<br />

            Publicly available sources: we may receive personal data about you from various public sources, including social media, company registries and court or tribunal websites.<br /><br />

            4. How we use your personal data.<br /><br />

            We will only process your personal data on the basis of legally permissible grounds. We will mainly use your personal data for the following reasons:<br />
            When we need to comply with a legal or regulatory obligation;<br />
            Where it is necessary for our legitimate interests (or those of a third party), and your interests and fundamental rights do not override those interests;<br />
            In order to satisfy our contractual obligations towards you.<br />

            The table below provides a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. <br />

            <table style={{ border: '1px solid black' }}>
                <thead style={{ border: '1px solid black' }}>
                    <tr style={{ border: '1px solid black' }}>
                        <td style={{ border: '1px solid black' }}>Purpose</td>
                        <td style={{ border: '1px solid black' }}>Data</td>
                        <td style={{ border: '1px solid black' }}>Lawful basis for processing in terms of the General Data Protection Regulation (Regulation [EU] 2016/679)</td>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{ border: '1px solid black' }}>
                        <td style={{ border: '1px solid black' }}>To register you as a new customer or subscribing you to special offers</td>
                        <td style={{ border: '1px solid black' }}>(a) Identity
                            (b) Contact
                            (c) Technical
                        </td>
                        <td style={{ border: '1px solid black' }}>Performance of a contract with you
                        </td>
                    </tr>
                    <tr style={{ border: '1px solid black' }}>
                        <td style={{ border: '1px solid black' }}>To process purchases:
                            (a) Manage payments, fees and charges
                            (b) Collect and recover money owed to us
                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Identity
                            (b) Contact
                            (c) Financial
                            (d) Transaction
                            (e) Marketing and Communications

                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Performance of a contract with you
                            (b) Necessary for our legitimate interests (to recover debts due to us)
                        </td>
                    </tr>
                    <tr style={{ border: '1px solid black' }}>
                        <td style={{ border: '1px solid black' }}>To manage our relationship, which will include:
                            (a) Notifying you about changes to our terms or privacy notice
                            (b) Asking you to give us feedback or take a survey
                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Identity
                            (b) Contact
                            (c) Profile
                            (d) Marketing and Communications
                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Performance of a contract with you
                            (b) Necessary to comply with a legal obligation
                            (c) Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)
                        </td>
                    </tr>
                    <tr style={{ border: '1px solid black' }}>
                        <td style={{ border: '1px solid black' }}>To enable you to partake in a prize draw, competition or complete a survey
                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Identity
                            (b) Contact
                            (c) Profile
                            (d) Usage
                            (e) Marketing and Communications
                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Performance of a contract with you
                            (b) Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)
                        </td>
                    </tr>
                    <tr style={{ border: '1px solid black' }}>
                        <td style={{ border: '1px solid black' }}>To administer and protect our business, Website and IT systems (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)
                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Identity
                            (b) Contact
                            (c) Technical

                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)
                            (b) Necessary to comply with a legal obligation
                        </td>
                    </tr>
                    <tr style={{ border: '1px solid black' }}>
                        <td style={{ border: '1px solid black' }}>To deliver relevant content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you
                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Identity
                            (b) Contact
                            (c) Profile
                            (d) Usage
                            (e) Marketing and Communications
                        </td>
                        <td style={{ border: '1px solid black' }}>Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)
                        </td>
                    </tr>
                    <tr style={{ border: '1px solid black' }}>
                        <td style={{ border: '1px solid black' }}>To use data analytics to improve our Website, products/services, marketing, customer relationships and experiences
                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Technical
                            (b) Usage
                        </td>
                        <td style={{ border: '1px solid black' }}>Necessary for our legitimate interests (to define types of customers for our products and services, to keep our Website updated and relevant, to develop our business and to inform our marketing strategy)
                        </td>
                    </tr>
                </tbody>
            </table>
            In some cases, your data may also be processed by one of our entrusted third party processors in terms of the current data protection laws, who will assist us in fulfilling our service standard. For more information on third party processors, please refer to section 5 of this privacy policy. The law further obliges us to share your data with competent authorities in certain situations as detailed under the relevant laws and regulations. <br /> <br />
            MARKETING.<br /> <br />

            You will receive marketing communications from us if you have actively requested this information from us and, or if you have purchased products or services from us and, in each case, you have not opted out of receiving that marketing.
            <br /> <br />
            You may request this information from us at any time by:<br />
            accessing here to adjust your marketing preferences;<br />
            contacting us at any time.<br /><br />

            OPTING OUT OF MARKETING.<br /><br />

            You can ask us to stop sending you marketing messages at any time by: <br />
            accessing here to adjust your marketing preferences; <br />
            following the opt-out links on any marketing message sent to you; <br />
            contacting us at any time.<br /><br />
            5. 	Disclosures.<br /><br />

            We may have to share your personal data with the third parties set out below, acting in the capacity of data processors, for the purposes set out in the table in paragraph 5 above.<br />

            <table style={{ border: '1px solid black' }}>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>PG Group entities</td>
                    <td style={{ border: '1px solid black' }}>Other companies within the PG Group, in particular Pama Supermarket Ltd (C67445), with whom we may share your personal data in order to properly render our services </td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>IT security</td>
                    <td style={{ border: '1px solid black' }}>Service providers who help Pavi in ensuring that your data remains secure</td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>IT backups</td>
                    <td style={{ border: '1px solid black' }}>Service providers who assist Pavi in relation to backups for business continuity purposes so that your data is not lost</td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>Auditing and payroll</td>
                    <td style={{ border: '1px solid black' }}>Service providers who carry out our statutory auditing as well as provide payroll assistance</td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>Administration</td>
                    <td style={{ border: '1px solid black' }}>Service providers who provide software and administrative assistance in order to enable us to better organise our internal administrative processes</td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>Third party consultants and professional advisers</td>
                    <td style={{ border: '1px solid black' }}>Experts who assist us in various regulatory compliance matters, including lawyers, auditors and insurers</td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>Payment services providers</td>
                    <td style={{ border: '1px solid black' }}>Service providers that facilitate payment transactions</td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>Marketing</td>
                    <td style={{ border: '1px solid black' }}>Service providers who assist us in relation to marketing</td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>Regulators and other authorities </td>
                    <td style={{ border: '1px solid black' }}>Public authorities that may require reporting of processing activities in certain circumstances</td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>Third party buyers or sellers</td>
                    <td style={{ border: '1px solid black' }}>Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business then the new owners may use our personal data in the same way as set out in this Privacy Notice.</td>
                </tr>
            </table>

            We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.<br /><br />
            6. 	International Transfers.

            We shall endeavour to send your data only to EU countries or third countries which ensure an adequate standard of protection for your data. When transferring your data to countries which are not deemed to offer such adequate protection, proper measures in terms of the law shall be applied to ensure the protection of your personal data. In cases where such measures cannot be achieved, personal data shall only be sent to these countries if such is necessary to perform our services, and shall be subject to your prior consent. <br /><br />
            7. Data security.<br /><br />

            We have implemented appropriate security measures to prevent your personal data from being accidentally lost, altered or disclosed in an unauthorized manner. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.
            <br /><br />
            We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.<br /><br />

            8.	Data retention.<br /><br />

            We hold your personal information only as long as we have a valid legal reason to do so, which includes satisfying any legal, accounting or reporting requirements.  <br /><br />

            To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.
            <br /><br />
            Details of retention periods for different aspects of your personal data are available in our Retention Policy which you can request from us by contacting us.
            <br /><br />
            In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.
            <br /><br />
            9.	Your rights.<br /><br />

            As a data subject, you have certain data protection rights at law:<br />

            ACCESS: you have the right to access your data and thereby request a copy thereof. <br />

            RECTIFICATION: you have the right to rectify incorrect data. If any of the data that we hold about you is incorrect, you may request that we rectify it.<br />

            ERASURE: you have the right to be forgotten, which enables you to ask us to delete your personal data where there is no good reason for us continuing to process it. On this point, note that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request. Note that we may not be able to provide you with some of our services if we do not hold your personal data.
            <br />
            RESTRICTIONS ON PROCESSING: you have the right to request the restriction of our processing. This can be done in the following cases: (i) if you want us to establish the data’s accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it. Note that we may not be able to provide you with some of our services if you opt to restrict processing.
            <br />
            PORTABILITY: you have the right to data protability. Your data may be requested in a machine-readable format and you may also request that your data is transfered directly to another person or service provider directly.
            <br />
            OBJECTIONS TO PROCESSING: you may object to the processing of your data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
            <br />
            WITHDRAWAL OF CONSENT: If you have provided consent for the processing of your data you have the right (in certain circumstances) to withdraw that consent at any time which will not affect the lawfulness of the processing before your consent was withdrawn.
            <br />
            If you wish to exercise any of the rights set out above, please contact us.<br /><br />

            You will not have to pay to access your personal data (or to exercise any of the other rights mentioned above). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may, in such circumstances, refrain from complying with your request.
            <br /><br />

            WHAT WE MAY NEED FROM YOU.<br /><br />

            We may ask you to send over specific information to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure.
            <br /><br />
            We try to respond to all legitimate requests within one month.
            <br /><br />

            COMPLAINTS.<br /><br />

            Should you feel wronged by our data protection practices, you may file a complaint with the data protection supervisory authority of your country of residence. In Malta, this would be the Office for the Information and Data Protection Commissioner, the contact details of which are as follows:
            <br /><br />
            OFFICE OF THE INFORMATION AND DATA PROTECTION COMMISSIONER [MALTA]
            Email: commissioner.dataprotection@gov.mtPhone: +356 2328 7100
            <br /><br />
            However, we strive to be receptive to your concerns and would appreciate it if you would contact us in the first instance should you believe that we have breached any privacy rules.
            <br /><br />
            <br /><br />
            <h2>Pama Privacy Policy</h2><br /><br />
            Pama is committed to protecting your personal data.<br /><br />
            We are aware of the discomfort brought about by having to read endless pages of complicated text. For this reason, our privacy policy – which can be accessed directly below – has been drafted in a manner that is simple and easy-to-read. <br /><br />
            This Privacy Notice was updated in May 2018. We may update it from time to time. If we believe that any changes will affect you, we will let you know before they are made. <br /><br />

            This privacy policy applies to the supermarket operations of Pama (https://www.pavipama.com.mt/) (the “Website”). It is important that you read this privacy policy together with any other privacy or fair processing notice we may provide on specific occasions when we collect your personal data so that you are fully aware of the manner in which we use your personal data.

            1.	Who we are.<br /><br />

            Throughout this privacy policy, the terms ‘we’ and ‘our’ refer to Pama Supermarket Limited (“Pama”), which forms part of the PG group of companies (https://pggroup.com.mt/) (“PG Group”). Pama is the controller of your personal data. As explained further below, Pama collects and handles your personal data when, for example, you sign up for the supermarket’s loyalty card schemes, create an account on our Website, and purchase our products.
            <br /><br />
            OUR CONTACT DETAILS.
            <br />
            Full name of controller: 			Pama Supermarket Limited<br />

            Company registration number: 		C67445<br />

            Postal address: 			PG Group Head Offices, Pama Shopping Village, Valletta			Road, Mosta MST9017, Malta<br />

            Email address: 				dataprotection@pggroup.com.mt<br />

            Telephone number: 			(+356) 2349 6100<br /><br />


            2.Data we collect about you.<br /><br />

            We may collect, use, store and transfer different kinds of personal data about you which we have grouped together follows:
            <br />
            Identity Data includes name, username or similar identifier, title, age and gender.<br />
            Contact Data includes home address, email address and telephone numbers.<br />
            Financial Data includes payment card details.<br />
            Transaction Data includes details about payments to and from you and other details of products you have purchased from us.<br />
            Technical Data includes IP address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this Website.<br />
            Profile Data includes your username and password, loyalty card details, purchases or orders made by you, your interests, feedback and survey responses.<br />
            Usage Data includes information about how you use our Website, products and services.<br />
            Marketing and Communications Data includes your preferences in receiving our promotional content.<br />
            Certain other information may be classified as special categories of personal data. Such include any information relating to the following;<br />
            Racial or ethnic origin;<br />
            Political opinions;<br />
            Religious or philosophical beliefs;<br />
            Trade union membership;<br />
            Genetic data;<br />
            Biometric data for the purpose of uniquely identifying a natural person;<br />
            Data concerning health;<br />
            Data concerning a natural person's sex life or sexual orientation.<br />
            Unless authorised or required by law, we will never store or process any data which is deemed to consist of special categories of data without obtaining your prior written consent.<br /><br />

            3. How do we collect your data?<br /><br />

            We collect your personal data through different methods, including:<br />

            Direct interactions: you may give us your Identity, Contact and Financial Data by filling in forms or by corresponding with us by phone, email or otherwise. This includes personal data you provide when you:<br />
            create an account on our Website;<br />
            purchase products through our Website;<br />
            subscribe to our loyalty card schemes;<br />
            send us your CV should you be interested in joining our team;<br />
            request marketing to be sent to you;<br />
            enter a competition, promotion or survey;<br />
            provide us with any feedback.<br /><br />

            Automated technologies or interactions: when you access our Website, we may automatically collect Technical Data about your devices and browsing patterns. We collect this personal data by using cookies and other similar technologies.<br />
            Cookies are text files which are placed on your computer, tablet or mobile phone when using our Website, to collect standard internet log information and visitor information on our Website. Our Website creates cookies every time you visit it. Cookies are used for the following reasons:<br />
            To analyse the traffic on our Website;<br />
            To make improvements to our Website;<br />
            To ensure that visits to our Websites are properly recorded.<br /><br />

            Publicly available sources: we may receive personal data about you from various public sources, including social media, company registries and court or tribunal websites.<br /><br />


            4. How we use your personal data.<br /><br />

            We will only process your personal data on the basis of legally permissible grounds. We will mainly use your personal data for the following reasons:<br />
            When we need to comply with a legal or regulatory obligation;<br />
            Where it is necessary for our legitimate interests (or those of a third party), and your interests and fundamental rights do not override those interests;<br />
            In order to satisfy our contractual obligations towards you.<br />

            GROUNDS FOR PROCESSING.<br />

            The table below provides a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data.<br /><br />


            <table style={{ border: '1px solid black' }}>
                <thead style={{ border: '1px solid black' }}>
                    <tr style={{ border: '1px solid black' }}>
                        <td style={{ border: '1px solid black' }}>Purpose</td>
                        <td style={{ border: '1px solid black' }}>Data</td>
                        <td style={{ border: '1px solid black' }}>Lawful basis for processing in terms of the General Data Protection Regulation (Regulation [EU] 2016/679)</td>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{ border: '1px solid black' }}>
                        <td style={{ border: '1px solid black' }}>To register you as a new customer or subscribing you to special offers</td>
                        <td style={{ border: '1px solid black' }}>(a) Identity
                            (b) Contact
                            (c) Technical
                        </td>
                        <td style={{ border: '1px solid black' }}>Performance of a contract with you
                        </td>
                    </tr>
                    <tr style={{ border: '1px solid black' }}>
                        <td style={{ border: '1px solid black' }}>To process purchases:
                            (a) Manage payments, fees and charges
                            (b) Collect and recover money owed to us
                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Identity
                            (b) Contact
                            (c) Financial
                            (d) Transaction
                            (e) Marketing and Communications

                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Performance of a contract with you
                            (b) Necessary for our legitimate interests (to recover debts due to us)
                        </td>
                    </tr>
                    <tr style={{ border: '1px solid black' }}>
                        <td style={{ border: '1px solid black' }}>To manage our relationship, which will include:
                            (a) Notifying you about changes to our terms or privacy notice
                            (b) Asking you to give us feedback or take a survey
                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Identity
                            (b) Contact
                            (c) Profile
                            (d) Marketing and Communications
                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Performance of a contract with you
                            (b) Necessary to comply with a legal obligation
                            (c) Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)
                        </td>
                    </tr>
                    <tr style={{ border: '1px solid black' }}>
                        <td style={{ border: '1px solid black' }}>To enable you to partake in a prize draw, competition or complete a survey
                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Identity
                            (b) Contact
                            (c) Profile
                            (d) Usage
                            (e) Marketing and Communications
                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Performance of a contract with you
                            (b) Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)
                        </td>
                    </tr>
                    <tr style={{ border: '1px solid black' }}>
                        <td style={{ border: '1px solid black' }}>To administer and protect our business, Website and IT systems (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)
                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Identity
                            (b) Contact
                            (c) Technical

                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)
                            (b) Necessary to comply with a legal obligation
                        </td>
                    </tr>
                    <tr style={{ border: '1px solid black' }}>
                        <td style={{ border: '1px solid black' }}>To deliver relevant content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you
                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Identity
                            (b) Contact
                            (c) Profile
                            (d) Usage
                            (e) Marketing and Communications
                        </td>
                        <td style={{ border: '1px solid black' }}>Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)
                        </td>
                    </tr>
                    <tr style={{ border: '1px solid black' }}>
                        <td style={{ border: '1px solid black' }}>To use data analytics to improve our Website, products/services, marketing, customer relationships and experiences
                        </td>
                        <td style={{ border: '1px solid black' }}>(a) Technical
                            (b) Usage
                        </td>
                        <td style={{ border: '1px solid black' }}>Necessary for our legitimate interests (to define types of customers for our products and services, to keep our Website updated and relevant, to develop our business and to inform our marketing strategy)
                        </td>
                    </tr>
                </tbody>
            </table>
            In some cases, your data may also be processed by one of our entrusted third party processors in terms of the current data protection laws, who will assist us in fulfilling our service standard. For more information on third party processors, please refer to section 5 of this privacy policy. The law further obliges us to share your data with competent authorities in certain situations as detailed under the relevant laws and regulations. <br /><br />


            MARKETING.<br /><br />

            You will receive marketing communications from us if you have actively requested this information from us and, or if you have purchased products or services from us and, in each case, you have not opted out of receiving that marketing.
            <br />
            You may request this information from us at any time by:
            accessing here to adjust your marketing preferences;
            contacting us at any time.
            <br /><br />
            OPTING OUT OF MARKETING.
            <br /><br />
            You can ask us to stop sending you marketing messages at any time by:<br />
            accessing here to adjust your marketing preferences;<br />
            following the opt-out links on any marketing message sent to you;<br />
            contacting us at any time.<br /><br />


            5. 	Disclosures.<br /><br />

            We may have to share your personal data with the third parties set out below, acting in the capacity of data processors, for the purposes set out in the table in paragraph 5 above.
            <br />
            <table style={{ border: '1px solid black' }}>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>PG Group entities</td>
                    <td style={{ border: '1px solid black' }}>Other companies within the PG Group, in particular Pama Supermarket Ltd (C67445), with whom we may share your personal data in order to properly render our services </td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>IT security</td>
                    <td style={{ border: '1px solid black' }}>Service providers who help Pavi in ensuring that your data remains secure</td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>IT backups</td>
                    <td style={{ border: '1px solid black' }}>Service providers who assist Pavi in relation to backups for business continuity purposes so that your data is not lost</td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>Auditing and payroll</td>
                    <td style={{ border: '1px solid black' }}>Service providers who carry out our statutory auditing as well as provide payroll assistance</td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>Administration</td>
                    <td style={{ border: '1px solid black' }}>Service providers who provide software and administrative assistance in order to enable us to better organise our internal administrative processes</td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>Third party consultants and professional advisers</td>
                    <td style={{ border: '1px solid black' }}>Experts who assist us in various regulatory compliance matters, including lawyers, auditors and insurers</td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>Payment services providers</td>
                    <td style={{ border: '1px solid black' }}>Service providers that facilitate payment transactions</td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>Marketing</td>
                    <td style={{ border: '1px solid black' }}>Service providers who assist us in relation to marketing</td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>Regulators and other authorities </td>
                    <td style={{ border: '1px solid black' }}>Public authorities that may require reporting of processing activities in certain circumstances</td>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black' }}>Third party buyers or sellers</td>
                    <td style={{ border: '1px solid black' }}>Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business then the new owners may use our personal data in the same way as set out in this Privacy Notice.</td>
                </tr>
            </table>
            We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.<br /><br />

            6. 	International Transfers.<br /><br />

            We shall endeavour to send your data only to EU countries or third countries which ensure an adequate standard of protection for your data. When transferring your data to countries which are not deemed to offer such adequate protection, proper measures in terms of the law shall be applied to ensure the protection of your personal data. In cases where such measures cannot be achieved, personal data shall only be sent to these countries if such is necessary to perform our services, and shall be subject to your prior consent.
            <br /><br />

            7. Data security.<br /><br />

            We have implemented appropriate security measures to prevent your personal data from being accidentally lost, altered or disclosed in an unauthorized manner. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.
            <br /><br />
            We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
            <br /><br />

            8.	Data retention.
            <br /><br />
            We hold your personal information only as long as we have a valid legal reason to do so, which includes satisfying any legal, accounting or reporting requirements.
            <br /><br />
            To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.
            <br /><br />
            Details of retention periods for different aspects of your personal data are available in our Retention Policy which you can request from us by contacting us.
            <br /><br />
            In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.
            <br /><br />

            9.	Your rights.
            <br /><br />
            As a data subject, you have certain data protection rights at law:
            <br /><br />
            ACCESS: you have the right to access your data and thereby request a copy thereof.
            <br />
            RECTIFICATION: you have the right to rectify incorrect data. If any of the data that we hold about you is incorrect, you may request that we rectify it.
            <br />
            ERASURE: you have the right to be forgotten, which enables you to ask us to delete your personal data where there is no good reason for us continuing to process it. On this point, note that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request. Note that we may not be able to provide you with some of our services if we do not hold your personal data.
            <br />
            RESTRICTIONS ON PROCESSING: you have the right to request the restriction of our processing. This can be done in the following cases: (i) if you want us to establish the data’s accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it. Note that we may not be able to provide you with some of our services if you opt to restrict processing.
            <br />
            PORTABILITY: you have the right to data protability. Your data may be requested in a machine-readable format and you may also request that your data is transfered directly to another person or service provider directly.
            <br />
            OBJECTIONS TO PROCESSING: you may object to the processing of your data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
            <br />
            WITHDRAWAL OF CONSENT: If you have provided consent for the processing of your data you have the right (in certain circumstances) to withdraw that consent at any time which will not affect the lawfulness of the processing before your consent was withdrawn.
            <br />
            If you wish to exercise any of the rights set out above, please contact us.
            <br />
            You will not have to pay to access your personal data (or to exercise any of the other rights mentioned above). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may, in such circumstances, refrain from complying with your request.
            <br /><br />
            WHAT WE MAY NEED FROM YOU.
            <br /><br />
            We may ask you to send over specific information to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure.
            <br /><br />
            We try to respond to all legitimate requests within one month.
            <br /><br />
            COMPLAINTS.
            <br /><br />
            Should you feel wronged by our data protection practices, you may file a complaint with the data protection supervisory authority of your country of residence. In Malta, this would be the Office for the Information and Data Protection Commissioner, the contact details of which are as follows:
            <br /><br />
            OFFICE OF THE INFORMATION AND DATA PROTECTION COMMISSIONER [MALTA]<br />
            Email:commissioner.dataprotection@gov.mt<br />
            Phone: +356 2328 7100<br /><br />

            However, we strive to be receptive to your concerns and would appreciate it if you would contact us in the first instance should you believe that we have breached any privacy rules.<br /><br />
        </div>
    )
}

export default Policy