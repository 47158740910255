import React from "react";
import { Route, Switch, Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import ProfileAddresses from "./ProfileAddresses/ProfileAddresses";
import BillingAddresses from "./ProfileAddresses/BillingAddresses";
import Profile from "./Profile/Profile";
import PasswordChange from "./PasswordChange/PasswordChange";
import { ReactComponent as CardIcon } from "../../images/card-points.svg"
import * as actionCreators from "../../redux/store/actions/index"
import { profileService } from "../../services/basis/ProfileService";
import { storesService } from "../../services/basis/StoresService";

import "./profile-container.styles.scss";

class ProfileContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            points: 0,
            stores: []
        }
    }

    componentDidMount() {
        const { user } = this.props;
        if (user.cards && user?.cards[0] && user?.cards[0]?.code) {
            profileService.points()
                .then(response => {
                    this.setState({ points: response.data?.pointBalance })
                })
                .catch(error => {
                    console.log(error)
                })
        }
        storesService.storesByDelivery("IN_STORE")
            .then(data => {
                this.setState({ stores: data })
            });
    }

    render() {
        const { user, favStore } = this.props;
        const { points, stores } = this.state;
        return (
            <Translation>
                {t =>
                    <div className="profile-container">
                        <div className="row no-gutters">
                            <div className="col-lg-4 col-sm-12">
                                <div className="profile-menu">
                                    <div className="profile-menu-title">
                                        {t("profileContainer.yourInfo")}
                                    </div>
                                    <div className="profile-menu-name">
                                        {user &&
                                            <span>{user.firstName} {user.lastName}</span>
                                        }
                                    </div>
                                    {user && user.cards && user.cards[0] &&
                                        <div className="profile-menu-points">
                                            <div className="card-icon">
                                                <CardIcon />
                                            </div>
                                            <div className="points">
                                                {t("profile.points")}<b>{points}</b>
                                            </div>
                                        </div>
                                    }
                                    <div className="favStore">
                                        <label>Your favourite store</label>
                                        <select value={favStore} onChange={e => this.props.updateFavouriteStore(e.target.value)}>
                                            {
                                                stores && stores.map(store => (
                                                    <option value={store.id} key={store.id}>
                                                        {store.name}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <hr />
                                    <div className={`profile-link 
                                    ${this.props.history.location.pathname === "/profile" ? "profile-link-active" : ""}`}>
                                        <Link className="link" to="/profile">
                                            {t("profileContainer.yourData")}
                                        </Link>
                                    </div>
                                    <div className={`profile-link 
                                    ${this.props.history.location.pathname === "/profile/addresses" ? "profile-link-active" : ""}`}>
                                        <Link className="link" to="/profile/addresses">
                                            {t("profileContainer.shippingAddress")}
                                        </Link>
                                    </div>
                                    {/* <div className={`profile-link 
                                    ${this.props.history.location.pathname === "/profile/billing" ? "profile-link-active" : ""}`}>
                                        <Link className="link" to="/profile/billing">
                                            {t("profileContainer.billingAddress")}
                                        </Link>
                                    </div> */}
                                    <div className={`profile-link 
                                    ${this.props.history.location.pathname === "/profile/password" ? "profile-link-active" : ""}`}>
                                        <Link className="link" to="/profile/password">
                                            {t("profileContainer.passwordChange")}
                                        </Link>
                                    </div>
                                    <button className="cta abort" onClick={this.props.logout}>
                                        <span>{t("profileContainer.logout")}</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-8 col-sm-12">
                                <div className="profile-content">
                                    <Switch>
                                        <Route exact path='/profile' render={() => <Profile />} />
                                        <Route exact path='/profile/addresses' render={() => <ProfileAddresses />} />
                                        <Route exact path='/profile/billing' render={() => <BillingAddresses />} />
                                        <Route exact path='/profile/password' render={() => <PasswordChange />} />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        favStore: state.user.user?.preferences?.storeId,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actionCreators.logout()),
        updateFavouriteStore: (data) => dispatch(actionCreators.updateFavouriteStore(data)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileContainer));


