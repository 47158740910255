/* eslint-disable */
import { useEffect } from "react";
import * as actionTypes from "../actions/actionTypes";
import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    user: JSON.parse(localStorage.getItem("user")),
    jwt: null,
    latitude: null,
    longitude: null,
    showLogin: false,
}

const userReducer = createReducer(initialState, {
    [actionTypes.LOGIN]: (state, action) => { state.user = action.user, state.jwt = action.jwt, state.showLogin = action.showLogin },
    [actionTypes.LOGOUT]: (state, action) => { state.user = action.user, state.jwt = action.jwt },
    [actionTypes.USER_UPDATE]: (state, action) => { state.user = action.user },
    [actionTypes.IS_LOGGED]: (state, action) => { state.user = action.user },
    [actionTypes.COORDINATE]: (state, action) => {
        state.latitude = action.latitude,
            state.longitude = action.longitude
    },
    [actionTypes.SHOW_LOGIN]: (state, action) => { state.showLogin = action.showLogin },
})

console.log(initialState.user);


export default userReducer;

