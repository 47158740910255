import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import Popup from "reactjs-popup";
import { useTranslation } from "react-i18next";
import ValidateInput from "../../../../components/UI/Input/ValidateInput";
import { addressesService } from "../../../../services/basis/AddressesService";

import "./address-popup.styles.scss";

function AddressPopup(props) {
    const { t } = useTranslation();
    const [city, setCity] = useState(props.address && props.address.city ? props.address.city : "");
    const [cities, setCities] = useState([]);

    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        console.log(data);
        props.action(data);
        document.getElementById("close-popup").click();
    }

    useEffect(() => {
        addressesService.cities()
            .then(response => {
                setCities(response.data);
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        register(
            { name: "city" },
            { required: t("required.field") },
        )
        setValue("city", city);
        setCity(city)
    }, [t, register, city, setValue])

    const selectCity = city => {
        setValue("city", city);
        setCity(city);
    }

    return (
        <Popup lockScroll={true} contentStyle={popupStyle} trigger={props.trigger} modal>
            {close => (
                <div className="address-popup">
                    <div id="close-popup" className="close" onClick={close}>
                        &times;
                    </div>
                    <div className="address-popup-header">
                        {props.title}
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                        className={`address-popup-form needs-validation ${errors ? "had-validation" : ""}`}>
                        <div className="address-popup-content">
                            <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
                            <div className="row popup-row">
                                <div className="col-sm-8 mb-3 mb-sm-0">
                                    <ValidateInput
                                        name="address"
                                        label="House Name/Number, Street Name*"
                                        type="text"
                                        value={props.address && props.address.address}
                                        watch={watch("address")}
                                        errors={errors.address}
                                        register={register({
                                            required: "true"
                                        })}
                                    />
                                </div>
                                <div className="col-xs-6 col-sm-4 mb-3 mb-sm-0">
                                    {/* <ValidateInput
                                                name="city"
                                                label="City"
                                                pattern="[A-Za-z\s]"
                                                type="text"
                                                value={props.address && props.address.city}
                                                watch={watch("city")}
                                                errors={errors.city}
                                                register={register}
                                            /> */}
                                    <select className="signup-select"
                                        name="city"
                                        value={city}
                                        onChange={(e) => selectCity(e.target.value)}>
                                        <option disabled selected value="">Select a city</option>
                                        {cities && cities.map(city => (
                                            <option key={city.id} value={city.name}>
                                                {city?.name?.toUpperCase()}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row popup-row">
                                <div className="col-sm-8 mb-3 mb-sm-0">
                                    <ValidateInput
                                        name="nation"
                                        label="Country"
                                        pattern="[A-Za-z\s]"
                                        type="text"
                                        // value={props.address && props.address.nation}
                                        value="Malta"
                                        disabled
                                        watch={watch("nation")}
                                        errors={errors.nation}
                                        register={register}
                                    />
                                </div>
                                <div className="col-xs-6 col-sm-4 mb-3 mb-sm-0">
                                    <ValidateInput
                                        name="zip"
                                        label="Post Code"
                                        type="text"
                                        value={props.address && props.address.zip}
                                        watch={watch("zip")}
                                        uppercase={true}
                                        maxLength={8}
                                        errors={errors.zip}
                                        register={register({
                                            pattern: {
                                                value: /[A-Z]{3} [0-9]{4}/,
                                            }
                                        })}
                                    />
                                </div>
                                {props.isUpdate &&
                                    <div className="d-none">
                                        <ValidateInput
                                            name="id"
                                            type="text"
                                            value={props.address && props.address.id}
                                            watch={watch("id")}
                                            errors={errors.id}
                                            register={register({
                                                required: "true"
                                            })}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="address-popup-actions">
                            {props.isUpdate &&
                                <button className="cta delete" onClick={() => {
                                    props.deleteAddress(props.address && props.address.id)
                                    close();
                                }}>
                                    <span>{t("addresses.delete")}</span>
                                </button>
                            }
                            <button type="submit" className="cta">
                                <span>{props.button}</span>
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </Popup>
    );
}

const popupStyle = {
    borderRadius: "15px"
}

export default AddressPopup;