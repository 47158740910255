const config = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    maxSize: 5,
    DEFAULT_TID: "01",
    // DEFAULT_STORE_ID : "200531",
    // DEFAULT_STORE_ID : "001",
    DEFAULT_STORE_DESCRIPTION : "PAMA Supermarket",
    SHOW_CART_LISTS: false,

    ENABLED_SLIST: true,
    ENABLED_PAYMENTS: false
}

export default config;