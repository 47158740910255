import React from "react"
import { Translation } from "react-i18next";

import "./validate-input.styles.scss";

const ValidateInput = ({ value, watch, label, register, errors, ...otherProps }) => {
    const filterInput = (event) => {
        if (otherProps.pattern 
            && event.key !== 'Backspace'
            && event.key !== 'ArrowLeft'
            && event.key !== 'ArrowRight'
            ) {
            const regex = RegExp(otherProps.pattern);
            if (!regex.test(event.key)) {
                event.preventDefault();
            }
        }
    }
    const transformInput = (event) => {
        if (otherProps.uppercase) {
            event.target.value = event.target.value.toUpperCase();
        }
    }
    return (
        <Translation>
            {t =>
                <div className="group">
                    <input className={`form-input ${errors ? "input-required" : ""}`}
                        onKeyDown={filterInput}
                        onChange={transformInput}
                        defaultValue={value}
                        ref={register}
                        {...otherProps}
                    />
                    {
                        label ?
                            (<label className={`${(watch && watch.length > 0) ||
                                (value && value.length > 0 && watch === undefined) ? "shrink" : ""} form-input-label`}>
                                {t(label)}
                            </label>)
                            : null
                    }
                </div>
            }
        </Translation>
    );
}

export default ValidateInput;