import React, { Component } from 'react';
import { Row, Col, Accordion, Card } from "react-bootstrap";
import OrderCard from '../../components/Cards/OrderCard';
import { ecommerceService } from "../../services/ecommerce/EcommerceService";
import { Translation } from 'react-i18next';
import Order from './Order/Order';
import NewPagination from '../../components/UI/Pagination/NewPagination';
import toaster from '../../utils/toaster';
import { format, parseISO } from "date-fns";
import { connect } from "react-redux";
import * as actionCreators from "../../redux/store/actions/index";
import PaymentFailedPopup from './PaymentFailedPopup/PaymentFailedPopup';
import qs from "query-string";

import "./orders.styles.scss"
import swal from '../../utils/swal';

class Orders extends Component {

    constructor(props) {
        super(props);

        this.readOrders = (page) => {
            ecommerceService.orders(page)
                .then(response => {
                    if (response.data.length > 0) {

                        ecommerceService.fetchOrder(response.data[0].id)
                            .then(fetchResponse => {
                                if (fetchResponse.data.status === "TO_BE_PREPARED" ||
                                    fetchResponse.data.status === "IN_PREPARATION" ||
                                    fetchResponse.data.status === "ABORTED" ||
                                    fetchResponse.data.status === "PAYMENT_PENDING") {
                                    this.setState({
                                        currentOrder: fetchResponse.data,
                                        items: fetchResponse.data.cart.items,

                                        orders: response.data,
                                        totalPages: response.totalPages,
                                        page: response.number,
                                        isLoaded: true,
                                    })
                                } else {
                                    this.setState({
                                        currentOrder: fetchResponse.data,
                                        items: fetchResponse.data.preparedCartItems,

                                        orders: response.data,
                                        totalPages: response.totalPages,
                                        page: response.number,
                                        isLoaded: true,
                                    })
                                }
                            }).catch((error) => {
                                console.log(error);
                            })
                    } else {
                        this.setState({ isLoaded: true })
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }

        this.state = {
            orders: [],
            currentOrder: null,
            items: [],
            isLoaded: false,
            page: 0,
            totalPages: null,

            showAlert: false,
            alertMessage: ""
        }
    }

    componentDidMount() {
        this.readOrders(this.state.page);
        const message = qs.parse(this.props.location.search).message;
        console.log(message);
        if (message) {
            this.setState({ showAlert: true, alertMessage: message })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentOrder !== this.state.currentOrder) {
            ecommerceService.fetchOrder(this.state.currentOrder.id)
                .then(response => {
                    if (this.state.currentOrder.status === "TO_BE_PREPARED" ||
                        this.state.currentOrder.status === "PAYMENT_PENDING" ||
                        this.state.currentOrder.status === "IN_PREPARATION" ||
                        this.state.currentOrder.status === "ABORTED") {
                        this.setState({
                            items: response.data.cart.items
                        })
                    } else {
                        this.setState({
                            items: response.data.preparedCartItems
                        })
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    closeAlert = () => this.setState({ showAlert: false });

    renderTotal = (item) => {
        let result;
        if (item.operatorCart && item.operatorCart.totalToPay) {
            result = `${item.operatorCart.totalToPay.toFixed(2)} \u20AC`
        } else {
            result = `${item.cart.totalToPay.toFixed(2)} \u20AC`
        }
        return result;
    }

    selectOrder = (orderId, status) => {
        if (orderId !== this.state.currentOrder.id)
            ecommerceService.fetchOrder(orderId)
                .then(response => {
                    if (status === "TO_BE_PREPARED" || status === "IN_PREPARATION" || status === "ABORTED") {
                        this.setState({
                            currentOrder: response.data,
                            items: response.data.cart.items
                        })
                    } else {
                        this.setState({
                            currentOrder: response.data,
                            items: response.data.preparedCartItems
                        })
                    }
                }).catch((error) => {
                    console.log(error);
                })
    }

    abort = (orderId) => {
        ecommerceService.abortOrder(orderId)
            .then(response => {
                this.setState({
                    currentOrder: response.data.order
                })
                this.readOrders(this.state.page);
                swal.success("Order successfully aborted")
            }).catch((error) => {
                toaster.error(error.message)
                console.log(error);
                swal.error("Something went wrong")
            })
    }

    changePage = (page) => {
        if (page >= 0 && page < this.state.totalPages) {
            this.readOrders(page);
        }
    }

    render() {
        const { orders, currentOrder, items, isLoaded, page, totalPages, showAlert, alertMessage } = this.state;
        if (isLoaded) {
            return (
                <>
                    <Translation>
                        {t =>
                            <>
                                {/* Only on large device */}
                                <div className="container-fluid orders d-none d-xl-block">
                                    <Row>
                                        <Col style={{ backgroundColor: "#fbfbfb" }} sm={12} md={12} lg={12} xl={5}>
                                            <div className="orders-container">
                                                {orders && orders.map((item) => (
                                                    <Row key={item.id}>
                                                        <OrderCard item={item} currentOrder={currentOrder} selectOrder={this.selectOrder} />
                                                    </Row>
                                                ))}
                                                {orders && orders.length === 0 &&
                                                    <span className="title">
                                                        {t("orders.empty")}
                                                    </span>
                                                }
                                                <Row className="offset-3">
                                                    <NewPagination page={page} totalPages={totalPages} changePage={this.changePage} />
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col style={{ minHeight: "400px" }} sm={12} md={12} lg={12} xl={7}>
                                            <Order order={currentOrder} items={items} abort={this.abort} copy={this.props.copy} history={this.props.history}/>
                                        </Col>
                                    </Row>
                                </div>
                                {/* Only on small device */}
                                <div className="view-container d-xl-none">
                                    <Accordion>
                                        {orders && orders.map((item, index) => (
                                            <Card key={item.id} className="mb-4">
                                                <Accordion.Toggle as={Card.Header} className={item.id === currentOrder.id ? "card-selected" : ""}
                                                    eventKey={`${index}`} onClick={() => { this.selectOrder(item.id, item.status) }}>
                                                    <Row style={{ marginBottom: "21px" }}>
                                                        <Col sm={5}>
                                                            <h5 className="card-text"><b>{item.cart.title}</b></h5>
                                                        </Col>
                                                        <Col sm={7}>
                                                            <h5 className="card-text">
                                                                {t("order.status")}:
                                                                <span className={`ml-1 order-status-${item.status}`}>
                                                                    <b>{t(`order.status.${item.status}`)}</b>
                                                                </span>
                                                            </h5>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={5}>
                                                            <p className="card-text">
                                                                {t("total")}: <b>{this.renderTotal(item)}</b>
                                                            </p>
                                                        </Col>
                                                        <Col sm={7}>
                                                            <p className="card-text">
                                                                {t("deliveryDate")}:    <b>{item.deliveryDate && format(parseISO(item.deliveryDate), "dd/MM/yyyy")}</b>
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={`${index}`}>
                                                    <Card.Body>
                                                        <Order order={currentOrder} items={items} abort={this.abort} copy={this.props.copy} history={this.props.history} />
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                        {orders && orders.length === 0 &&
                                            <span className="title">
                                                {t("orders.empty")}
                                            </span>
                                        }
                                    </Accordion>
                                    <Row className="justify-content-center">
                                        <NewPagination page={page} totalPages={totalPages} changePage={this.changePage} />
                                    </Row>
                                </div>
                                <PaymentFailedPopup
                                    show={showAlert}
                                    closeAlert={this.closeAlert}
                                    alertMessage={alertMessage}
                                />
                            </>
                        }
                    </Translation>
                </>
            );
        } else {
            return null;
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        copy: (orderId) => dispatch(actionCreators.copy(orderId)),
    };
}

export default connect(null, mapDispatchToProps)(Orders);