import * as actionTypes from "./actionTypes"
import { authenticationService } from "../../../services/basis/AuthenticationService"
import { history } from "../../../App"
import toaster from "../../../utils/toaster"
import { cartAction, availabilityAction, addProductToAddAction } from "./cart"
import { ecommerceCartsService } from "../../../services/ecommerce/EcommerceCartsService"
import { ecommerceService } from "../../../services/ecommerce/EcommerceService"
import { favorites, slists } from "./slists"
import { smartlistService } from "../../../services/slists/SmartlistService"
import config from "../../../config/config"
import swal from "../../../utils/swal"
import i18n from "../../../i18n"

export const loginAction = (user, jwt, showModal) => {
    return {
        type: actionTypes.LOGIN,
        user: user,
        jwt: jwt,
        showLogin: showModal
    }
}

export const login = (data) => {
    return (dispatch, getState) => {
        authenticationService.login(data)
            .then(response => {
                dispatch(loginAction(response.data.profile, response.data.jwt, false))

                // get current cart 
                ecommerceCartsService.current()
                    .then(response => {
                        dispatch(cartAction(response.data))

                        // get first availability
                        ecommerceService.availability()
                            .then(response => {
                                dispatch(availabilityAction(response.data))
                            }).catch((error) => {
                                console.log(error);
                            })

                        // add unauthenticathed product
                        if (getState().cart.productToAdd) {
                            const item = getState().cart.productToAdd;
                            ecommerceCartsService.store(response.data.id, item.barcode, item.amount, item.um)
                                .then((response) => {
                                    const um = response.data.updatedProduct.um;
                                    dispatch(addProductToAddAction(response.data.cart))
                                    toaster.standard(`${i18n.t("cart.addedProduct.youHave")} ${item.amount} ${um} ${response.data.updatedProduct.description} ${i18n.t("cart.addedProduct.inCart")}`)
                                }).catch((error) => {
                                    console.log(error);
                                    toaster.error(error.message);
                                })
                        }


                    }).catch(error => {
                        console.log(error);
                        if (getState().cart.productToAdd) {
                            history.push("/cart/create")
                        }
                    })
                if (config.ENABLED_SLIST) {
                    // get favorites
                    smartlistService.getFavorites()
                        .then(response => {
                            dispatch(favorites(response.data))
                        }).catch((error) => {
                            console.log(error);
                        })
                    // get slists
                    smartlistService.getSlists()
                        .then(response => {
                            dispatch(slists(response.data))
                        }).catch((error) => {
                            console.log(error);
                        })
                }
            }).catch((error) => {
                console.log(error);
                dispatch(loginAction(null, true))
                toaster.error(i18n.t("error.wrongCredentials"))
            })
    }
};

export const logoutAction = () => {
    return {
        type: actionTypes.LOGOUT,
        user: null,
        jwt: null,
    }
}

export const logout = () => {
    //faccio sempre la logoutAction per evitare problemi di 401
    return dispatch => {
        history.push("/")
        dispatch(logoutAction())
        dispatch(cartAction(null))
        dispatch(slists([]))
    }
};

export const isLoggedAction = (user) => {
    return {
        type: actionTypes.IS_LOGGED,
        user: user
    }
}

export const isLogged = () => {
    return dispatch => {
        authenticationService.isLogged()
            .then(response => {
                dispatch(isLoggedAction(response.data))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const coordinateAction = (latitude, longitude) => {
    return {
        type: actionTypes.COORDINATE,
        latitude: latitude,
        longitude: longitude
    }
}

export const coordinate = (latitude, longitude) => {
    return dispatch => {
        dispatch(coordinateAction(latitude, longitude))
    }
};

export const updateUserAction = (user) => {
    return {
        type: actionTypes.USER_UPDATE,
        user: user
    }
}

export const updateUser = (data) => {
    return dispatch => {
        authenticationService.updateUser(data)
            .then(response => {
                dispatch(updateUserAction(response.data))
                swal.success('Profile updated successfully');
            }).catch((error) => {
                swal.error('Error while updating the profile');
                console.log(error);
            })
    }
};

export const updateFavouriteStore = (data) => {
    return dispatch => {
        authenticationService.updateFavouriteStore(data)
            .then(response => {
                dispatch(updateUserAction(response.data))
                swal.success('Favourite store updated successfully');
            }).catch((error) => {
                swal.error('Error while updating the store');
                console.log(error);
            })
    }
};

export const showLogin = (show) => {
    return dispatch => {
        dispatch(showLoginAction(show))
    }
};

export const showLoginAction = (show) => {
    return {
        type: actionTypes.SHOW_LOGIN,
        showLogin: show
    }
}
