import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SignupDatepicker from "../SignupDatepicker";
import ValidateInput from '../../../components/UI/Input/ValidateInput';
import { useTranslation } from 'react-i18next';

export default function SignupCheck(props) {
    const { register, handleSubmit, errors, setValue, watch } = useForm({
        mode: 'onBlur',
    });

    const { data, emailField } = props;
    const { t } = useTranslation();
    const [birthDate, setBirthdate] = useState(data ? data.birthDate : null);
    // const [birthDate, setBirthdate] = useState();

    useEffect(() => {
        setValue("gender", data?.gender)
    }, [])

    useEffect(() => {
        if (props.type === "check") {
            setValue("birthDate", birthDate);
        }
    }, [t, register, birthDate, setValue])

    const handleChange = date => {
        setValue("birthDate", date);
        setBirthdate(date);
        if (date) {
            let birthDate = new Date(date)
            let now = new Date()
            let isUnder18 = (now.getFullYear() - birthDate.getFullYear()) < 18
            if (isUnder18){
                errors.birthDate = {};
                errors.birthDate.message = "You must be over 18 years old";
            } else {
                errors.birthDate = {};
                errors.birthDate.message = "";
            }
        }
    }

    const onSubmit = data => {
        if (props.type === "fetch") {
            props.signupCheck(data)
        } else {
            props.nextStep(data);
        }
    }

    return (
        <div className="signup-content">
            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="new-password"
                className={`needs-validation ${errors ? "had-validation" : ""}`}>
                <div className="signup-card">
                    {props.type === "check" &&
                        <>
                            <Row>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="firstName"
                                        label={`${t("name")}*`}
                                        type="text"
                                        pattern="[A-Za-z\s]"
                                        watch={watch("firstName")}
                                        value={data && data.firstName}
                                        register={register({
                                            required: t("required.field"),
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.firstName && errors.firstName.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="lastName"
                                        label={`${t("surname")}*`}
                                        type="text"
                                        pattern="[A-Za-z\s]"
                                        watch={watch("lastName")}
                                        value={data && data.lastName}
                                        register={register({
                                            required: t("required.field"),
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.lastName && errors.lastName.message}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="form-group">
                                    <div className="d-flex">
                                        <div className="gender">{t("gender")}</div>
                                        <div className="radio-button">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input"
                                                    id="male"
                                                    name="gender"
                                                    value="MALE"
                                                    // ref={register({ required: t("required.field"), })}
                                                />
                                                <label className="custom-control-label" htmlFor="male">
                                                    {t("male")}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="radio-button">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input"
                                                    id="female"
                                                    name="gender"
                                                    value="FEMALE"
                                                    // ref={register({ required: t("required.field"), })}
                                                />
                                                <label className="custom-control-label" htmlFor="female">
                                                    {t("female")}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-control-invalid">
                                        {errors.gender && errors.gender.message}
                                    </div>
                                </Col>
                                <Col md={6} className="form-group">
                                    <SignupDatepicker
                                        label={`${t("birthDate")}`}
                                        onChange={handleChange}
                                        value={birthDate}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.birthDate && errors.birthDate.message}
                                    </div>
                                </Col>
                            </Row>
                        </>
                    }
                    {props.type === "fetch" &&
                        <>
                            {props.emailField &&
                                <Row style={{ alignItems: "baseline" }}>
                                    <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="email"
                                            label={t("email")}
                                            type="email"
                                            watch={watch("email")}
                                            value={data && data.email}
                                            register={register({
                                                required: t("required.field"),
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: t("error.invalidEmail")
                                                }
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.email && errors.email.message}
                                        </div>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="emailConfirm"
                                            label="E-mail Confirm*"
                                            type="email"
                                            watch={watch("emailConfirm")}
                                            value={data && data.emailConfirm}
                                            register={register({
                                                required: t("required.field"),
                                                validate: {
                                                    passwordMatch: value => value === watch('email')
                                                }
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.emailConfirm && errors.emailConfirm.message}
                                            {errors.emailConfirm && errors.emailConfirm.type === "passwordMatch" && (
                                                <span>{t("signup.emailsDoesNotMatch")}</span>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            }
                            <Row style={{ alignItems: "baseline" }}>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="cardNumber"
                                        label={`${t("profileCard")}*`}
                                        type="text"
                                        watch={watch("cardNumber")}
                                        value={data && data.cardNumber}
                                        pattern="[0-9]"
                                        register={register({
                                            required: t("required.field"),
                                            pattern: {
                                                value: /^0400[0-9]{9}$/i,
                                                message: t("error.invalidCard")
                                            }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.cardNumber && errors.cardNumber.message}
                                    </div>
                                    <small className="form-text"
                                        style={{ fontSize: "11px" }}>
                                        <span style={{ fontFamily: "Montserrat-Bold", textTransform: "uppercase" }}>
                                            Loyalty Card No should be composed of 13 digits starting with 0400 “ex: 0400123456789”
                                        </span>
                                    </small>
                                    {/* <div className="card-alert">
                                    <Alert />
                                    <span>{t("signup.card.alert")}</span>
                                </div> */}
                                </Col>
                                <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="documentId"
                                        label={`${t("documentId")}*`}
                                        type="text"
                                        watch={watch("documentId")}
                                        value={data && data.documentId}
                                        register={register({
                                            required: t("required.field"),
                                            pattern: {
                                                // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                // message: t("error.invalidCard")
                                            }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.documentId && errors.documentId.message}
                                    </div>
                                    <small className="form-text"
                                        style={{ fontSize: "11px" }}>
                                        <span style={{ fontFamily: "Montserrat-Bold", textTransform: "uppercase" }}>
                                            If Maltese ID Card, format should be: NNNNNNNL “ex: 0123456M”
                                        </span> (N=number; L=Letter)
                                    </small>
                                </Col>
                            </Row>
                        </>
                    }
                </div>
                <div className="signup-actions">
                    <button className="secondary-cta" type="button" onClick={() => props.previousStep()}>
                        <span> {`< ${t("goBack")}`}</span>
                    </button>
                    <button className="cta" type="submit">
                        <span>{t("next")}</span>
                    </button>
                </div>
            </form>
        </div>
    )
}
