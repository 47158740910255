import config from "../../config/config"
import { handleResponse } from "../../utils/handleResponse";
// import { checkResponse } from "../../utils/checkResponse";
import { authHeader } from "../../utils/authHeader";
import axios from 'axios';

export default function contactUs(data, recaptcha) {
    return (
        axios.post(`${config.baseUrl}/cli/contact/us`,
            {
                name: data.name,
                surname: data.surname,
                phoneNumber: data.phoneNumber,
                email: data.email,
                subject: data.subject,
                message: data.message,
                phoneNumberPrefix: data.prefix
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "g-recaptcha-response": recaptcha,
                }
            })
            .then(handleResponse)
        // .then(checkResponse)
    );
}
