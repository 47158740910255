import config from "../../../../config/config"
import { handleResponse } from "../../../../utils/handleResponse";
import { checkResponse } from "../../../../utils/checkResponse";
import { authHeader } from "../../../../utils/authHeader";
import axios from 'axios';

export default function paymentsMode(deliveryMode) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/configuration/deliveryMode/${deliveryMode}`, {
            headers: authHeader()
        })
        .then(handleResponse)
        .then(checkResponse)
    );
}
