import CustomError from "./customError";
import i18n from "../i18n"
import toaster from "./toaster";
import swal from "./swal";

export function checkResponse(response) {
    if (response.responseCode !== 0) {
        throw new CustomError(response.responseCode, errorMessage(response));
    }

    return response;
}

function errorMessage(response) {
    switch (response.responseCode) {
        case 2:
            return i18n.t("error.userNotFound")

        case 4:
            return i18n.t("error.invalidUsername")

        case 30:
            return i18n.t("error.invalidPhone")

        case 42:
            return i18n.t("error.deliveryNotEnabled")

        case 2035:
            return i18n.t("error.lastSlist")

        case 2222:
            return i18n.t("error.homeServiceNotEnabled")

        case 4000:
            return i18n.t("error.genericError")

        case 4004:
            return i18n.t("error.cartNotFound")

        case 4008:
            swal.warning('Warning', i18n.t("error.oldCartWithItem"))
            return i18n.t("error.oldCartWithItem")

        case 4028:
            return i18n.t("error.timeslotNotAvailable")

        case 4029:
            return i18n.t("error.timeExceeded")

        case 4030:
            return i18n.t("error.productNotFound")

        case 4031:
            return i18n.t("error.productNotValid")

        case 4034:
            return i18n.t("error.maximumReached")

        case 4037:
            return i18n.t("error.missingBillingAddress")

        case 4038:
            return i18n.t("error.bundlesExceeded")

        case 4205:
            return i18n.t("error.orderWrongStatus")

        default:
            return response.errorMessage;
    }
}