import React from "react";
import Row from "react-bootstrap/Row"
// import PaymentsLogo from "../../../../images/payment_circuits.png"
import payments from "./Service"
import { paymentsService } from "../../../Payments/Service"
import i18n from "../../../../i18n"
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom";

class PaymentsMode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentsMode: [],
            isBoarded: false
        };
    }

    componentDidMount() {
        const deliveryMode = this.props.cart.deliveryMode;
        payments(deliveryMode)
            .then(response => {
                this.setState({
                    paymentsMode: response.data,
                })
            }).catch((error) => {
                console.log(error);
            })

        paymentsService.paymenthMethods()
            .then(response => {
                console.log(response);
                if (response.data.length > 0) {
                    response.data.forEach(method => {
                        console.log(method);
                        if (method.paymentMethodType === "APCOPAY") {
                            this.setState({ isBoarded: true })
                        }
                    });
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    renderButtons = (paymentMode) => {
        const { paymentMethod, setPaymentMethod } = this.props;
        switch (paymentMode) {
            case "CASH":
                return (
                    <div className="d-flex">
                        <div className="radio-button mr-3">
                            <div className="custom-control custom-radio">
                                <input type="radio" className="custom-control-input"
                                    id="CASH"
                                    name="CASH"
                                    value="CASH"
                                    checked={paymentMethod === "CASH"}
                                    onChange={() => setPaymentMethod("CASH")}
                                />
                                <label className="custom-control-label" style={{ textTransform: "uppercase" }} htmlFor="CASH">
                                    {i18n.t("paymentMode.cash.title")}
                                </label>
                            </div>
                        </div>
                    </div>
                );
            case "APCOPAY":
                return (
                    <div className="d-flex">
                        <div className="radio-button mr-3">
                            <div className="custom-control custom-radio">
                                <input type="radio" className="custom-control-input"
                                    id="APCOPAY"
                                    name="APCOPAY"
                                    value="APCOPAY"
                                    checked={paymentMethod === "APCOPAY"}
                                    onChange={() => setPaymentMethod("APCOPAY")}
                                />
                                <label className="custom-control-label" style={{ textTransform: "uppercase" }} htmlFor="APCOPAY">
                                    {i18n.t("paymentMode.online.title")}
                                </label>
                            </div>
                        </div>
                    </div>
                );
            case "BANCOMAT":
                return (
                    <div className="d-flex">
                        <div className="radio-button mr-3">
                            <div className="custom-control custom-radio">
                                <input type="radio" className="custom-control-input"
                                    id="BANCOMAT"
                                    name="BANCOMAT"
                                    value="BANCOMAT"
                                    checked={paymentMethod === "BANCOMAT"}
                                    onChange={() => setPaymentMethod("BANCOMAT")}
                                />
                                <label className="custom-control-label" style={{ textTransform: "uppercase" }} htmlFor="BANCOMAT">
                                    {i18n.t("paymentMode.bancomat.title")}
                                </label>
                            </div>
                        </div>
                    </div>
                );
            case "CREDIT_CARD":
                if (this.state.isBoarded) {
                    return (
                        <>
                            {/* Only on large device */}
                            <button className="outline d-none d-lg-block" onClick={() => this.freezeCart(paymentMode)}>
                                <div className="to-hide">
                                    <span className="primary-text" style={{ fontSize: "12px" }}>
                                        {i18n.t("paymentMode.online.title")}</span>
                                </div>
                                <div className="hover-text">{i18n.t("paymentMode.BANCOMAT")}</div>
                            </button>
                            {/* Only on small device */}
                            <button className="outline d-lg-none mobile" onClick={() => this.freezeCart(paymentMode)}>
                                <div>{i18n.t("paymentMode.BANCOMAT")}</div>
                            </button>
                        </>
                    );
                } else {
                    return (
                        <Link style={{ width: "100%" }} to="/payments">
                            <div className="addMethod">
                                <span className="primary-text" style={{ fontSize: "12px" }}>
                                    Aggiungi metodo di pagamento online
                                </span>
                            </div>
                        </Link>
                    );
                }

            default: return null;
        }
    }

    render() {
        const { paymentsMode } = this.state;
        const { paymentMethod } = this.props;
        return (
            <Translation>
                {t =>
                    <div style={{ marginBottom: "40px" }} className="w-100">
                        <div className="checkout-data-title">{t("checkout.paymentsMode.title")}</div>
                        <div className="container-fluid">
                            {paymentsMode && paymentsMode.map(paymentMode => (
                                <Row style={{ marginBottom: "15px" }} key={paymentMode}>
                                    {this.renderButtons(paymentMode)}
                                </Row>
                            ))}
                            {paymentMethod === "APCOPAY" &&
                                <label style={{fontFamily: 'Montserrat-Bold'}}>Kindly note that if an online payment is not finalised within the stipulated timeframe, the order will be automatically aborted.</label>
                            }
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

export default PaymentsMode;


