import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import config from '../../config/config';
import { authHeader } from "../../utils/authHeader";
import axios from 'axios';

export const leafletsService = { leaflets, fetchLeaflet };

function leaflets() {
    return (
        axios.get(`${config.baseUrl}/cli/cms/leaflets`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function fetchLeaflet(leafletId) {
    return (
        axios.get(`${config.baseUrl}/cli/cms/leaflets/${leafletId}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}