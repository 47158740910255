import React, { useState, useEffect } from "react"
import { Form } from "react-bootstrap";
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import ValidateInput from "../../components/UI/Input/ValidateInput";
import contactUs from "./Service";
import swal from "../../utils/swal";
import ReCAPTCHA from "react-google-recaptcha";
import { history } from "../../App";
import { addressesService } from "../../services/basis/AddressesService";

import "./contact_us.styles.scss"

function ContactUs(props) {
    const [prefixes, setPrefixes] = useState([]);
    const [prefix, setPrefix] = useState();

    useEffect(() => {
        addressesService.prefixes()
            .then(response => {
                setPrefixes(response.data);
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        register(
            { name: "prefix" },
            { required: t("required.field") },
        )
    }, [prefix])

    const { t } = useTranslation()

    const recaptchaRef = React.createRef();
    const [disableButton, setDisableButton] = useState(true);

    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onBlur',
    });


    const onSubmit = data => {
        console.log(data);
        const recaptcha = recaptchaRef.current.getValue();
        contactUs(data, recaptcha)
            .then(response => {
                console.log(response);
                swal.success("The message has been successfully sent");
                history.push("/")
            }).catch((error) => {
                swal.error("Error while sending the message");
            })
    }

    return (
        <div className="contactUs">
            <div className="contactUs-title">Contact us</div>
            {/* <div className="contactus-subtitle">
                        <div className="bold">
                            Stai cercando un lavoro con orari flessibili  e dove puoi dare il tuo valore aggiunto?
                        </div><br />
                        <div>
                            Potrai decidere tu quanto guadagnare, sia nel preparare la spesa, sia nel fare le consegne e tieni conto che le mance sono tutte tue.
                        </div>
                        <div>
                            Potrai diventare il punto di riferimento nella tua zona per la spesa e i servizi del tuo quartiere.
                        </div>
                        <div>
                            Potrai inserire tu stesso le attività che pensi possano essere di valore aggiunto per i clienti  traendo profitto.
                        </div>
                        Se tutto questo ti stimola siamo pronti a farti entrare nella  grande famiglia di <div className="supermercato">SUPERMERCATO.CASA</div>
                    </div> */}
            <div className="contactUs-card">
                <div className="contactUs-card-title">Fill in the form</div>
                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                    className={`needs-validation ${errors ? "had-validation" : ""}`}>
                    <div className="row">
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <ValidateInput
                                name="name"
                                label="firstName"
                                type="text"
                                watch={watch("name")}
                                register={register({
                                    required: t("required.field"),
                                })}
                            />
                            <div className="form-control-invalid">
                                {errors.name && errors.name.message}
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <ValidateInput
                                name="surname"
                                label="lastName"
                                type="text"
                                watch={watch("surname")}
                                register={register({
                                    required: t("required.field"),
                                })}
                            />
                            <div className="form-control-invalid">
                                {errors.surname && errors.surname.message}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-2 col-md-2">
                            <select className="contactUs-select"
                                name="prefix"
                                value={prefix}
                                onChange={(e) => {setPrefix(e.target.value); setValue("prefix", e.target.value);}}
                                watch={watch('prefix')}>
                                <option disabled selected value="">Prefix</option>
                                {prefixes && prefixes.map(item => (
                                    <option key={item.id} value={item.phonePrefix}>
                                        + {item?.phonePrefix}
                                    </option>
                                ))}
                            </select>
                            <div className="form-control-invalid">
                                {errors.prefix && errors.prefix.message}
                            </div>
                        </div>
                        <div className="col-10 col-md-4 mb-4 mb-md-0">
                            <ValidateInput
                                name="phoneNumber"
                                label={t("mobilePhone")}
                                maxLength='15'
                                type="text"
                                watch={watch("phoneNumber")}
                                register={register({
                                    required: t("required.field"),
                                    pattern: {
                                        value: /^\d+$/,
                                        message: t("error.invalidPhone")
                                    }
                                })}
                            />
                            <div className="form-control-invalid">
                                {errors.phoneNumber && errors.phoneNumber.message}
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <ValidateInput
                                name="email"
                                label={t("email")}
                                type="email"
                                watch={watch("email")}
                                register={register({
                                    required: t("required.field"),
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: t("error.invalidEmail")
                                    }
                                })}
                            />
                            <div className="form-control-invalid">
                                {errors.email && errors.email.message}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <ValidateInput
                                name="subject"
                                label="Subject*"
                                type="text"
                                watch={watch("subject")}
                                register={register({
                                    required: t("required.field"),
                                })}
                            />
                            <div className="form-control-invalid">
                                {errors.subject && errors.subject.message}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            {/* <ValidateInput
                                name="message"
                                label="Message*"
                                type="text"
                                watch={watch("message")}
                                register={register({
                                    required: t("required.field"),
                                })}
                            /> */}
                            <label className="message-label">Message*</label>
                            <Form.Control
                                as="textarea"
                                className="signup-textarea checkout-textarea w-100"
                                name="message"
                                type="text"
                                rows="4"
                                watch={watch("message")}
                                ref={register({
                                    required: t("required.field"),
                                })}
                            />
                            <div className="form-control-invalid">
                                {errors.message && errors.message.message}
                            </div>
                        </div>
                    </div>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA}
                        ref={recaptchaRef}
                        onChange={() => setDisableButton(false)}
                        onExpired={() => setDisableButton(true)}
                    />
                    <div className="text-right">
                        <button disabled={disableButton} type="submit" className="cta">
                            <span>{t("submit")}</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ContactUs;
