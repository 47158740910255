import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import config from '../../config/config';
import { authHeader } from '../../utils/authHeader';
import axios from "axios";

export const storesService = {
    stores, states, storesByDelivery, storesCoordinate, storesByZipcode, firstSlot
};

function stores(page, query) {
    return (
        axios.get(`${config.baseUrl}/cli/stores/ecommerce?s=${config.maxSize}&p=${page}&q=${query}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function storesCoordinate(page, lat, lon, query) {
    return (
        axios.get(`${config.baseUrl}/cli/stores/ecommerce?s=${config.maxSize}&p=${page}&lat=${lat}&lon=${lon}&q=${query}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function states() {
    return (
        axios.get(`${config.baseUrl}/cli/stores/states`)
            .then(handleResponse)
        //.then(checkResponse)
    );
}

function storesByDelivery(deliveryMode) {
    return (
        axios.get(`${config.baseUrl}/cli/stores/ecommerce/delivery?delivery=${deliveryMode}`)
            .then(handleResponse)
        // .then(checkResponse)
    );
}

function storesByZipcode(zipcode) {
    return (
        axios.get(`${config.baseUrl}/cli/stores/ecommerce?zipcode=${zipcode}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function firstSlot(storeId, deliveryMode, addressCity) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/stores${storeId ? '/' + storeId : ''}/delivery/${deliveryMode}/slots/first/available?tid=${config.DEFAULT_TID}&city=${addressCity ? addressCity : ""}`, {
            headers: authHeader(),
        })
            .then(handleResponse)
            .then((response) => {
                if (response.responseCode === 2 || response.responseCode === 0 || response.responseCode === 6007 || response.responseCode === 6008){
                    return response;
                } else {
                    checkResponse(response);
                }
            })
    );
}