import React from "react"
import disponibility from "./Service"
import Slider from "../../../../components/UI/Slider/Slider";
import moment from "moment";
import { Translation } from 'react-i18next';

export default class Days extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            days: [],
        };
    }

    componentDidMount() {
        disponibility(this.props.cartId)
            .then(response => {
                console.log(response);
                if (response.data.length > 0) {
                    this.setState({
                        days: response.data,
                    })
                    this.props.selectDay(response.data[0]?.weekDay);
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.cartId !== this.props.cartId) {
            disponibility(this.props.cartId)
                .then(response => {
                    if (response.data.length > 0) {
                        this.setState({
                            days: response.data,
                        })
                        this.props.selectDay(response.data[0]?.weekDay);
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    render() {
        const { days } = this.state;
        const { day } = this.props;
        if (days && days.length > 0) {
            return (
                <Translation>
                    {t =>
                        <Slider>
                            {days && days.map((item) => (
                                <button key={item.weekDay} id={item.weekDay}
                                    className={`disponibility-button outline title 
                                        ${day === item.weekDay ? "disponibility-button-selected" : ""}
                                        ${item.available ? "" : "disponibility-button-disabled"}
                                    `}
                                    onClick={() => this.props.selectDay(item.weekDay)}>
                                    <div className="text-center">
                                        <span>{t(`daysOfWeek.${moment(item.day).format("dddd")}`)}</span>
                                    </div>
                                    <div className="text-center" style={{ fontSize: '30px' }}>
                                        <span className="day">{moment(item.day).date()}</span>
                                    </div>
                                    <div className="text-center">
                                        <span>{t(`months.${moment(item.day).format('MMMM')}`)}</span>
                                    </div>
                                </button>
                            ))}
                        </Slider>
                    }
                </Translation>
            );
        } else {
            return (
                <Translation>
                    {t =>
                        <span className="text-center primary-text mb-4">
                            {t("days.primaryText")}
                        </span>
                    }
                </Translation>
            );
        }
    }
}