import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "./new-counter.styles.scss";

const NewCounter = (props) => {

    const filterInput = (event) => {
        if (event.key === "." && props.um === "PZ") {
            event.preventDefault();
        }
        if (props.pattern && event.key !== 'Backspace' && event.key !== '.') {
            const regex = RegExp(props.pattern);
            if (!regex.test(event.key)) {
                event.preventDefault();
            }
        }
    }

    const value = props.value ? `${props.value} ${props.um?.toLowerCase()}` : `${props.um?.toLowerCase()}`
    return (
        <div style={{ ...props.style }} className="new-counter">
            <button className="minus outline" onClick={props.decrement}
                disabled={props.disabled}>
                <FontAwesomeIcon icon="minus" size="1x" />
            </button>

            <div className="amount">
                {props.writable
                    ?
                    <input
                        type="tel"
                        value={props.value}
                        onKeyDown={filterInput}
                        onChange={props.changeAmount}
                        maxLength={3}
                    />
                    :
                    props.showUm ? value : props.value
                }
            </div>

            <button className="plus outline" onClick={props.increment}
                disabled={props.disabled || props.disablePlus}>
                <FontAwesomeIcon icon="plus" size="1x" />
            </button>

        </div>
    );
}


export default NewCounter;