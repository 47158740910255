import React, { useEffect, useState } from 'react'
import { leafletsService } from "./Service"
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom"
import { format, parseISO } from "date-fns";

import "./leaflets.styles.scss";

const Leaflets = () => {

    const [leaflets, setLeaflets] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        leafletsService.leaflets().then(response => {
            setLeaflets(response.data)
        });
    }, [])

    return (
        <div className="leaflets">
            <div className="leaflets-title">{t("leaflets.title")}</div>
            <div className="leaflets-content">
                {/* <div className="row">
                    {leaflets && leaflets.map(item => (
                        <div key={item.id} className="col-sm-4">
                            <Link to={`/leaflets/${item.id}`}>
                                <div key={item.id}>
                                    <div className="leaflet-image">
                                        <img src={item.coverImage} alt="" />
                                    </div>
                                    <h6>{item.title}</h6>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div> */}
                <div className="grid">
                    {leaflets && leaflets.map(item => (
                        <div key={item.id} className="">
                            <Link to={`/leaflets/${item.id}`} style={{ textDecoration: "none" }}>
                                <div key={item.id}>
                                    <img src={item.coverImage} alt="" />
                                    <div className="leaflet-title">{item.title}</div>
                                    {/* <div className="leaflet-subtitle">
                                        {t("validFrom")} {format(parseISO(item.validFrom), "dd/MM/yyyy")} {t("validTo")} {format(parseISO(item.validTo), "dd/MM/yyyy")}
                                    </div> */}
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Leaflets;

