import React from "react";
import getCategories from "../../features/Categories/Service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./category-select.styles.scss";

class CategorySelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            text: "Category",
            categories: [],
            selected: false
        }
    }

    componentDidMount() {
        getCategories().then(response => {
            this.setState({ categories: response.data })
        }).catch((error) => {
            console.log(error);
        })
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.globalClickListener)
    }

    globalClickListener = () => {
        this.setState({ isOpen: false }, () => {
            document.removeEventListener('click', this.globalClickListener)
        })
    }

    toggleOpen = () => {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }), () => {
            if (this.state.isOpen) {
                document.addEventListener('click', this.globalClickListener)
            }
        })
    }

    handleChange = (category) => {
        this.setState({
            isOpen: false,
            text: category.description,
            selected: true
        })
        this.props.setCategory(category)
    }

    clearCategory = () => {
        this.setState({
            isOpen: false,
            selected: false,
            text: "Category"
        })
        this.props.setCategory(null)
    }

    render() {
        const { isOpen, text, categories, selected } = this.state;
        return (
            <div className="category-select">
                <div className="category-button" onClick={this.toggleOpen}>
                    <span>{text}</span>
                    {selected ?
                        <FontAwesomeIcon icon={"times-circle"} size="1x" onClick={this.clearCategory} />
                        :
                        <FontAwesomeIcon icon={isOpen ? "caret-up" : "caret-down"} size="1x" />
                    }
                </div>
                {isOpen &&
                    <div className="category-dropdown">
                        {categories && categories.map(item => (
                            <div className="category-dropdown-item"
                                key={item.id}
                                onClick={() => this.handleChange(item)}>
                                {item.description}
                            </div>
                        ))}
                    </div>
                }
            </div>
        );
    }
}

export default CategorySelect;
