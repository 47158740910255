import config from "../../../config/config"
import { handleResponse } from "../../../utils/handleResponse";
import { checkResponse } from "../../../utils/checkResponse";
import axios from 'axios';

export default function banners() {
    return (
        axios.get(`${config.baseUrl}/cli/cms/banners?category=WEB&type=ECOMMERCE`)
            .then(handleResponse)
            .then(checkResponse)
    );
}