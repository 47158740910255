import React from "react"
import categories from "./Service"
import Slider from "react-slick";
import Offerte from "../../images/categories/offerte.png"
import { withRouter } from 'react-router-dom'
import { Translation } from "react-i18next";

import './category-slider.styles.scss'

class CategorySlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            isLoaded: false,
            dragging: false
        }
    }

    chooseCategory = (category) => {
        if (!this.state.dragging) {
            this.props.history.push({
                pathname: '/category',
                state: { category }
            })
        }
    }

    setDragging = (isDragging) => {
        this.setState({ dragging: isDragging })
    }

    componentDidMount() {
        categories().then(response => {
            this.setState({
                categories: response.data,
                isLoaded: true
            })
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {
        const { categories, isLoaded } = this.state;
        const settings = {
            infinite: true,
            slidesToShow: 12,
            slidesToScroll: 4,
            beforeChange: () => this.setDragging(true),
            afterChange: () => this.setDragging(false),
            draggable: true,
            arrows: true,
            // variableWidth: true,
            // nextArrow: <SampleNextArrow />,
            // prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        infinite: false,
                        slidesToShow: 10,
                        slidesToScroll: 4,
                        draggable: true,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        swipeToSlide: true,
                        arrows: false,
                        draggable: true
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        swipeToSlide: true,
                        arrows: false,
                        draggable: true
                    }
                }
            ]
        }
        if (isLoaded) {
            return (
                <Translation>
                    {t =>
                        <div className="category-slider-wrapper">
                            <Slider {...settings}>
                                <div className="category-slider-card" onClick={() => {
                                    this.props.history.push({
                                        pathname: "/search",
                                        state: { searchInput: "", onlyPromotions: true }
                                    })
                                }}>
                                    <img src={Offerte} alt="" />
                                    <div className="category-slider-content">
                                        {t("category.offers")}
                                    </div>
                                </div>
                                {categories && categories.map(category => (
                                    <div className="category-slider-card" key={category.id} onClick={() => this.chooseCategory(category)}>
                                        <img src={category.imageUrl} alt="" />
                                        <div className="category-slider-content">
                                            {category.description}
                                        </div>
                                    </div>
                                ))
                                }
                            </Slider>
                        </div>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    }
}

// function SampleNextArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <div
//             className={className}
//             style={{ ...style }}
//             onClick={onClick}
//         />
//     );
// }

// function SamplePrevArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <div
//             className={className}
//             style={{ ...style }}
//             onClick={onClick}
//         />
//     );
// }

export default withRouter(CategorySlider)