import React, { useState, useEffect } from "react"
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ValidateInput from "../../../components/UI/Input/ValidateInput";
import { addressesService } from "../../../services/basis/AddressesService";

import "../signup.styles.scss";

const SignupStep2 = (props) => {
    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onBlur',
    });

    const { t } = useTranslation();
    const { data } = props;
    const [city, setCity] = useState(data ? data.city : "");
    const [cities, setCities] = useState([]);
    const [prefixes, setPrefixes] = useState([]);
    const [mobilePhonePrefix, setMobilePhonePrefix] = useState(data && data.mobilePhonePrefix ? data.mobilePhonePrefix : "356");
    const [homePhonePrefix, setHomePhonePrefix] = useState(data && data.homePhonePrefix ? data.homePhonePrefix : "356");
    useEffect(() => {
        addressesService.cities()
            .then(response => {
                setCities(response.data);
            })
            .catch(error => {
                console.log(error)
            })
        addressesService.prefixes()
            .then(response => {
                setPrefixes(response.data);
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        register(
            { name: "city" },
            { required: t("required.field") },
        )
        register(
            { name: "mobilePhonePrefix" },
            { required: t("required.field") },
        )
        register(
            { name: "homePhonePrefix" },
        )
        setValue("city", city);
        setCity(city)
        setValue("mobilePhonePrefix", mobilePhonePrefix);
        setMobilePhonePrefix(mobilePhonePrefix)
        setValue("homePhonePrefix", homePhonePrefix);
        setHomePhonePrefix(homePhonePrefix)
    }, [t, register, city, mobilePhonePrefix, homePhonePrefix, setValue])

    const selectCity = city => {
        setValue("city", city);
        setCity(city);
    }

    const selectMobilePhonePrefix = prefix => {
        setValue("mobilePhonePrefix", prefix);
        setMobilePhonePrefix(prefix)

        setValue("mobilePhone", "");
    }

    const selectHomePhonePrefix = prefix => {
        setValue("homePhonePrefix", prefix);
        setHomePhonePrefix(prefix)

        setValue("homePhone", "");
    }

    const onSubmit = data => {
        props.nextStep(data);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="new-password"
            className={`signup needs-validation ${errors ? "had-validation" : ""}`}>
            <div className="signup-content">
                <div className="signup-card">
                    <Row>
                        <Col md={12} className="form-group">
                            <ValidateInput
                                name="address"
                                label="House Name/Number, Street Name*"
                                type="text"
                                value={data && data.address}
                                watch={watch("address")}
                                register={register({
                                    required: t("required.field"),
                                })}
                            />
                            <div className="form-control-invalid">
                                {errors.address && errors.address.message}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {/* <Col md={6} className="form-group">
                                    <ValidateInput
                                        name="nation"
                                        label="Country*"
                                        pattern="[A-Za-z\s]"
                                        type="text"
                                        maxLength={15}
                                        value={data && data.nation}
                                        watch={watch("nation")}
                                        register={register({
                                            required: t("required.field"),
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.nation && errors.nation.message}
                                    </div>
                                </Col> */}

                        <Col md={6} className="form-group">
                            <select className="signup-select"
                                name="city"
                                value={city}
                                onChange={(e) => selectCity(e.target.value)}>
                                <option disabled selected value="">Select a city</option>
                                {cities && cities.map(city => (
                                    <option key={city.id} value={city.name}>
                                        {city?.name?.toUpperCase()}
                                    </option>
                                ))}
                            </select>
                            <div className="form-control-invalid">
                                {errors.city && errors.city.message}
                            </div>
                        </Col>
                        <Col md={6} className="form-group">
                            <ValidateInput
                                name="zip"
                                label="Post Code*"
                                type="text"
                                value={data && data.zip}
                                watch={watch("zip")}
                                maxLength={8}
                                uppercase={true}
                                register={register({
                                    required: t("required.field"),
                                    pattern: {
                                        value: /[A-Z]{3} [0-9]{4}/,
                                        message: t("error.invalidZip")
                                    }
                                })}
                            />
                            <div className="form-control-invalid">
                                {errors.zip && errors.zip.message}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} className="form-group">
                            <select className="signup-select"
                                name="mobilePhonePrefix"
                                value={mobilePhonePrefix}
                                onChange={(e) => selectMobilePhonePrefix(e.target.value)}>
                                {/* <option disabled selected value="">Prefix</option> */}
                                {prefixes && prefixes.map(item => (
                                    <option key={item.id} value={item.phonePrefix}>
                                        + {item?.phonePrefix} - {item?.name}
                                    </option>
                                ))}
                            </select>
                            <div className="form-control-invalid">
                                {errors.mobilePhonePrefix && errors.mobilePhonePrefix.message}
                            </div>
                        </Col>
                        <Col md={7} className="form-group">
                            <ValidateInput
                                name="mobilePhone"
                                label={t("mobilePhone")}
                                maxLength={mobilePhonePrefix === "356" ? 8 : 11}
                                type="tel"
                                value={data && data.mobilePhone}
                                watch={watch("mobilePhone")}
                                pattern="[0-9]"
                                disabled={!mobilePhonePrefix}
                                register={register({
                                    required: t("required.field"),
                                    pattern: {
                                        value: mobilePhonePrefix === "356" ? /[79][0-9]{7}/ : "",
                                        message: t("error.invalidPhone")
                                    }
                                })}
                            />
                            <div className="form-control-invalid">
                                {errors.mobilePhone && errors.mobilePhone.message}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} className="form-group">
                            <select className="signup-select"
                                name="homePhonePrefix"
                                value={homePhonePrefix}
                                onChange={(e) => selectHomePhonePrefix(e.target.value)}>
                                {/* <option disabled selected value="">Prefix</option> */}
                                {prefixes && prefixes.map(item => (
                                    <option key={item.id} value={item.phonePrefix}>
                                        + {item?.phonePrefix} - {item?.name}
                                    </option>
                                ))}
                            </select>
                            <div className="form-control-invalid">
                                {errors.homePhonePrefix && errors.homePhonePrefix.message}
                            </div>
                        </Col>
                        <Col md={7} className="form-group">
                            <ValidateInput
                                name="homePhone"
                                label="HomePhone"
                                maxLength={homePhonePrefix === "356" ? 8 : 11}
                                type="tel"
                                value={data && data.homePhone}
                                watch={watch("homePhone")}
                                pattern="[0-9]"
                                disabled={!homePhonePrefix}
                                register={register({
                                    pattern: {
                                        value: homePhonePrefix === "356" ? /2[0-9]{7}/ : "",
                                        message: t("error.invalidPhone")
                                    }
                                })}
                            />
                            <div className="form-control-invalid">
                                {errors.homePhone && errors.homePhone.message}
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="signup-actions">
                    <button className="secondary-cta" type="button" onClick={() => props.previousStep(watch())}>
                        <span> {`< ${t("goBack")}`}</span>
                    </button>
                    <button className="cta" type="submit">
                        <span>{t("next")}</span>
                    </button>
                </div>
            </div>
        </form>
    );
}

export default SignupStep2;